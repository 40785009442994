import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyData } from '@app/interfaces/policy.interface';
import { BillingInfoService } from '@shared/services/billing-info.service';
import { BillingStore } from '@app/interfaces/billing-store.interface';
import { BillingStoreService } from './store/billing-store.service';
import { AbstractControl, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';
import { constants } from './../@shared/constants';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';
import { AptFeatureFlagService } from '@archinsurance-da/arch-portal-tools';
const DEFAULT_PRODUCT = 'essentialpl';
import { DatePipe, formatDate } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  detectChgEff: boolean = false;
  policyStore: BillingStore = {};
  USETESTDATA: boolean = false;
  BYPASSBINDCALL: boolean = true; //set to true for pay first bind after, false will bind before going to payment
  addressString: string = undefined;
  bindDialog: boolean = false;
  policyRequested: boolean = false;
  paramPolicyId: string;
  editingContact: boolean = false;
  editingInfo: boolean = false;
  // updateContactInfo: any = {
  //   insuredContactFirstName: '',
  //   insuredContactLastName: '',
  //   insuredEmailTxt: '',
  //   insuredPhoneNo: '',
  // };
  updateInfo: any = {
    effectiveDate: '',
    limit: '',
    limitAggregate: '',
    retention: '',
  };
  coverageLiteralName: string;
  downloading: boolean = false;
  paramProduct: string = DEFAULT_PRODUCT;
  limitList: any[] = [];
  retentionList: any[] = [];
  installments: any[] = [];
  limitAndRetentionFetched = false;
  TESTING_IPG: boolean = false;

  mustGoBackToReferrer: boolean = false;
  contactNumber: any;
  contactInfo: string = `Arch Customer Support | customerservice@archinsurance.com | `; //A string of text to display under the timeout error
  editable: boolean = true;
  pageLoadErrorMessage: string = '';
  pageLoadErrorTitle: string = '';
  showErrorDialog: boolean = false;

  goBackToReferrer() {
    history.back();
  }

  paymentPlans: any = {
    essentialpl: {
      '25DP9': {
        code: '25DP9',
        installments: [],
      },
    },
    ea: {
      '2PAY60': {
        code: '2PAY60',
        installments: [],
      },
      '4PAY40': {
        code: '4PAY40',
        installments: [],
      },
    },
  };
  //quote status, if bound send to payment, if issued show info screen
  promptPaymentSchedule: boolean = false;
  theme: Theme;
  goToConfirm() {
    if (this.policyStore.paymentProposal) {
      this.router.navigate(['direct/confirm']);
    } else {
      this.promptPaymentSchedule = true;
      setTimeout(() => {
        this.promptPaymentSchedule = false;
      }, 1500);
    }
  }

  overridePastEffDateByMCIDs: string[] = [];
  policyIsPastExempt: boolean;
  editingDisabled: boolean = false;
  agentCode: any = '18089';
  emptyContactMsg: string = '';
  datePipe = new DatePipe('en-US');
  constructor(
    private fb: UntypedFormBuilder,
    private billingInfoService: BillingInfoService,
    private billingStoreService: BillingStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private themeService: ThemingService,
    private ffService: AptFeatureFlagService
  ) {}

  producer: string = ''; //default nationwide
  setProducer(overrideProducerId?: string) {
    if (!/^P/.test(overrideProducerId) && overrideProducerId !== 'generic' && overrideProducerId !== 'auto') {
      overrideProducerId = `P${overrideProducerId}`;
    }

    // console.log('overrideProducerId', overrideProducerId);

    if (overrideProducerId && overrideProducerId != 'default') {
      this.producer = overrideProducerId;
    } else {
      this.producer = 'P43274'; //TODO: NEED TO GET FROM POLICY RESPONSE
    }

    if (overrideProducerId === 'generic') {
      this.themeService.setTheme('generic');
    } else {
      if (this.paramProduct == 'ea') {
        this.producer = 'P43580'; //Canopy
      }

      if (constants.producerIds.hasOwnProperty(this.producer) && constants.producerIds[this.producer].theme) {
        this.themeService.setTheme(constants.producerIds[this.producer].theme);
      } else if (!this.producer) {
        this.themeService.setTheme('generic');
      }
    }
  }

  get userProfile(): UserProfile {
    return this.authenticationService.userProfile;
  }

  setOverridePastEffDateByMCIDsRule() {
    // console.log('this.overridePastEffDateByMCIDs', this.overridePastEffDateByMCIDs);
    const routeParams = this.route.snapshot.paramMap;
    const policyId = routeParams.get('id');
    const policyinList: boolean = this.overridePastEffDateByMCIDs && this.overridePastEffDateByMCIDs.includes(policyId);
    this.policyIsPastExempt = policyinList;
    this.editingDisabled = policyinList;
  }

  phoneMessages = {
    error: {
      pattern: 'Phone number must only be numbers, dashes, and periods.',
    },
  };

  // contactInfoForm = this.fb.group({
  //   fname: ['', Validators.required],
  //   lname: ['', Validators.required],
  //   email: ['', [Validators.required, Validators.email]],
  //   phone: ['', [Validators.required, Validators.pattern('^[0-9.-]*$')]],
  // });

  infoForm = this.fb.group({
    effDate: ['', [Validators.required, this.effDateValidator()]],
    limit: ['', Validators.required],
    retention: ['', [Validators.required]],
  });

  effDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let dateVal = this.datePipe.transform(control.value, 'MM/dd/yyyy');

      const validDate = this.isValidDate(dateVal);
      const currentDate = this.isCurrentDate(dateVal);

      if (validDate && (currentDate || this.policyIsPastExempt)) {
        //also check format
        return null;
      } else {
        let ret = {};

        if (!currentDate) {
          ret['pastDate'] = {
            value: dateVal,
            message: 'Cannot be in the past or more than 60 days in the future',
          };
        }

        if (!validDate) {
          ret['invalidDate'] = {
            value: dateVal,
            message: 'Invalid date. Must be MM/dd/YYYY',
          };
        }

        return ret;
      }
    };
  }

  isValidDate(s: string) {
    // Assumes s is "mm/dd/yyyy"
    if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(s)) {
      return false;
    }
    const parts = s.split('/').map((p) => parseInt(p, 10));
    parts[0] -= 1;
    const d = new Date(parts[2], parts[0], parts[1]);
    return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
  }

  isCurrentDate(dateString: string) {
    const validDate = this.isValidDate(dateString);
    let isCurrentDate = false;
    if (validDate) {
      let dateObj = new Date(dateString);
      dateObj.setHours(0, 0, 0, 0);
      let nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);
      let sixtyDate = new Date();
      sixtyDate.setDate(sixtyDate.getDate() + 60);
      sixtyDate.setHours(0, 0, 0, 0);

      // console.log('dateObj', dateObj);
      // console.log('sixtyDate', sixtyDate);

      isCurrentDate = dateObj >= nowDate && dateObj <= sixtyDate;
    }
    return isCurrentDate;
  }

  contactInfoError: string = '';
  infoError: string = '';
  savingContactInfo: boolean = false;
  savingInfo: boolean = false;
  lastContactInfo: any = {};
  lastInfo: any = {};
  disableContactInfoCancel: boolean = false;
  disableInfoCancel: boolean = false;

  // doEditContactInfo() {
  //   if (this.policyStore?.policyData?.policy.quoteStatus === 'QTD') {
  //     this.lastContactInfo = JSON.parse(JSON.stringify(this.updateContactInfo)); //copy object
  //     this.editingContact = true;
  //   } else {
  //     this.editingContact = false;
  //   }
  // }

  doEditInfo() {
    if (this.policyStore?.policyData?.policy.quoteStatus === 'QTD') {
      this.lastInfo = JSON.parse(JSON.stringify(this.updateInfo)); //copy object
      this.editingInfo = true;
    } else {
      this.editingInfo = false;
    }
  }

  updatingMessages: any[] = [
    'Your time is very important to us.',
    'Your quote is being updated.',
    'Your quote will be ready shortly. Thank you for your patience.',
  ];

  // submitContactForm(): void {
  //   this.savingContactInfo = true;
  //   this.contactInfoError = '';
  //   this.disableContactInfoCancel = false;

  //  // this.policyStore.policyData.policy.insuredContactPerson =
  //   //  this.contactInfoForm.value.fname + ' ' + this.contactInfoForm.value.lname;
  // //  this.policyStore.policyData.policy.insuredEmailTxt = this.contactInfoForm.value.email;
  // //  this.policyStore.policyData.policy.insuredPhoneNo = this.contactInfoForm.value.phone;

  //   //update original response json
  //  // this.policyStore.policyData.responseJSON.policy.Insured_Contact_Person =
  //     this.policyStore.policyData.policy.insuredContactPerson;
  //  // this.policyStore.policyData.responseJSON.policy.Insured_Email_Txt =
  //     this.policyStore.policyData.policy.insuredEmailTxt;
  //   //this.policyStore.policyData.responseJSON.policy.Insured_Phone_No =
  //     this.policyStore.policyData.policy.insuredPhoneNo;

  //   this.submitForm();
  // }

  submitInfoForm(): void {
    let limit = this.convertInfoToval();
    if (
      this.policyStore?.policyData?.policy.policyLimit != limit[0] ||
      this.policyStore?.policyData?.policy.policyAggregateRetention !=
        this.inverseCommaToString(this.infoForm.value.retention)
    ) {
      this.detectChgEff = true;
    }
    if (this.detectChgEff == true) {
      this.savingInfo = true;
      this.infoError = '';
      this.disableInfoCancel = false;

      //let effectiveDate = this.infoForm.value.effDate;
      let effectiveDate = this.datePipe.transform(this.infoForm.value.effDate, 'MM/dd/yyyy');

      this.policyStore.policyData.policy.policyEffectiveDate = effectiveDate;

      let expDateObj = new Date(effectiveDate);
      expDateObj.setFullYear(expDateObj.getFullYear() + 1);
      // this.policyStore.policyData.policy.policyExpirationDate = `${
      //   expDateObj.getMonth() + 1
      // }/${expDateObj.getDate()}/${expDateObj.getFullYear()}`;
      this.policyStore.policyData.policy.policyExpirationDate = expDateObj.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });

      this.policyStore.policyData.policy.retroactiveDate = effectiveDate;
      this.policyStore.policyData.policy.transactionEffectiveDate = effectiveDate;

      this.policyStore.policyData.responseJSON.policy.policyEffDt =
        this.policyStore.policyData.policy.transactionEffectiveDate;
      this.policyStore.policyData.responseJSON.policy.policyExpDt =
        this.policyStore.policyData.policy.policyExpirationDate;
      this.policyStore.policyData.responseJSON.policy.retroactiveDate =
        this.policyStore.policyData.policy.retroactiveDate;

      this.policyStore.policyData.policy.quoteCoverageList = [
        {
          coverageId: '',
          aprPremium: '',
          itdPremium: '',
          extAction: 'U',
          action: null,
          coverageCodeInfo: {
            coverageLiteralName: this.coverageLiteralName,
            coverageCodeName: 'SPL',
          },
          limitAmt: limit[0],
          aggregateAmt: limit[1],
          deductibleAmount: this.inverseCommaToString(this.infoForm.value.retention),
          occurenceLimit: null,
          coverageTriggerCode: null,
          aggregateLimit: null,
          appCodeLimit: null,
          deductibleTypeCode: null,
          deductibleAppCode: null,
          asLob: null,
          sublineCode: null,
          limitTypeCode: null,

          aggregateDeductibleAmount: null,

          sumlimit1Amt: null,
          sumlimit2Amt: null,
          thirdPartyDeductible: null,
          litigationDateThirdParty: null,

          basePremium: null,

          aggregateRetention: null,
          deductible3Amount: null,
          coverageEffectiveDt: null,
          coverageExpirationDt: null,
          litigationDate: null,
          //"coveragecommissionAmt": "303.4000",
          annualPremium: null,
          prorateFactor: null,
          proratedPremium: null,
          prevCovExpDt: null,
          litigationDateDesc: null,
          itdCovCommissionAmt: null,
          cyberCLimit: null,
          retroactiveDate: this.policyStore.policyData.policy.retroactiveDate,
          retroactiveDateDesc: null,
          waitingPeriod: null,
          fpaInd: null,
          subLimitList: null,
          partOf1Amt: null,
          enteredPremium: null,
        },
      ];
      this.billingStoreService.setBillingStore(this.policyStore);
      this.billingInfoService.updatePolicy(this.policyStore.policyData).subscribe((data) => {
        // this.editingContact = false;
        if (data && data.returnCode == 'SUCCESS') {
          this.savingInfo = false;
          let policyEffDate = new Date(this.policyStore.policyData.policy.policyEffectiveDate);
          let policyExpDate = new Date(this.policyStore.policyData.policy.policyExpirationDate);
          let policyRetroDate = new Date(this.policyStore.policyData.policy.retroactiveDate);
          this.policyStore.effectiveDate = policyEffDate.toISOString().split('T').shift();
          this.policyStore.expirationDate = policyExpDate.toISOString().split('T').shift();
          this.policyStore.retroactiveDate = policyRetroDate.toISOString().split('T').shift();
          this.policyStore.policyData.policy.policyAggregateRetention = this.inverseCommaToString(
            this.infoForm.value.retention
          );

          let limit = this.convertInfoToval();

          this.policyStore.policyData.policy.policyLimit = limit[0];
          this.policyStore.policyData.policy.limitAggregate = limit[1];
          // Update contact info for non edit view
          let formateffectiveDate = this.datePipe.transform(this.infoForm.value.effDate, 'MM/dd/yyyy');
          this.updateInfo = {
            // effectiveDate: this.infoForm.value.effDate,
            effectiveDate: formateffectiveDate,
            limit: limit[0],
            limitAggregate: limit[1],
            retention: this.inverseCommaToString(this.infoForm.value.retention),
          };
          this.editingInfo = false;

          //RELOAD PAYMENT SCHEDULE
          // this.getPaymentSchedule();//handled by shared component now

          location.reload();
        } else {
          console.error('Failed to update', data);
          this.infoError = 'Unable to update  information. Please contact us for support.';
        }
      });
    } else {
      this.editingInfo = false;
    }
  }

  convertInfoToval() {
    let limitVal = this.infoForm.value.limit.split(' / ');
    let limit = this.inverseCommaToString(this.removeCurrencySign(limitVal[0]));
    let limitAggregate = this.inverseCommaToString(this.removeCurrencySign(limitVal[1]));
    return [limit, limitAggregate];
  }

  submitForm(): void {
    this.billingStoreService.setBillingStore(this.policyStore);
    this.billingInfoService.updatePolicy(this.policyStore.policyData).subscribe((data) => {
      // this.editingContact = false;
      if (data && data.returnCode == 'SUCCESS') {
        this.savingContactInfo = false;
        let policyEffDate = new Date(this.policyStore.policyData.policy.policyEffectiveDate);
        let policyExpDate = new Date(this.policyStore.policyData.policy.policyExpirationDate);
        let policyRetroDate = new Date(this.policyStore.policyData.policy.retroactiveDate);
        this.policyStore.effectiveDate = policyEffDate.toISOString().split('T').shift();
        this.policyStore.expirationDate = policyExpDate.toISOString().split('T').shift();
        this.policyStore.retroactiveDate = policyRetroDate.toISOString().split('T').shift();

        // Update contact info for non edit view
        // this.updateContactInfo = {
        //   insuredContactFirstName: this.contactInfoForm.value.fname,
        //   insuredContactLastName: this.contactInfoForm.value.lname,
        //   insuredEmailTxt: this.contactInfoForm.value.email,
        //   insuredPhoneNo: this.contactInfoForm.value.phone,
        //   // effectiveDate: this.contactInfoForm.value.effDate,
        // };
        this.editingContact = false;

        //RELOAD PAYMENT SCHEDULE
        // this.getPaymentSchedule();//handled by shared component now
      } else {
        console.error('Failed to update', data);
        this.contactInfoError = 'Unable to update contact information. Please contact us for support.';
      }
    });
  }
  onChangeEffDate(event: any) {
    if (event.target.value) {
      this.effdateChange(event.target.value);
      this.detectChgEff = true;
    }
  }

  effdateChange(effDate: string) {
    let effectiveDate = this.datePipe.transform(effDate, 'MM/dd/yyyy');

    this.policyStore.policyData.policy.policyEffectiveDate = effectiveDate;

    let expDateObj = new Date(effectiveDate);
    expDateObj.setFullYear(expDateObj.getFullYear() + 1);
    // this.policyStore.policyData.policy.policyExpirationDate = `${
    //   expDateObj.getMonth() + 1
    // }/${expDateObj.getDate()}/${expDateObj.getFullYear()}`;
    this.policyStore.policyData.policy.policyExpirationDate = expDateObj.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    this.policyStore.policyData.policy.retroactiveDate = effectiveDate;
  }

  // cancelContactInfo() {
  //   this.editingContact = false;

  //   //reset info
  //   this.updateContactInfo = JSON.parse(JSON.stringify(this.lastContactInfo)); //copy object
  //   //also reset the form field values
  //   // this.contactInfoForm.patchValue({
  //   //   fname: this.updateContactInfo.insuredContactFirstName,
  //   //   lname: this.updateContactInfo.insuredContactLastName,
  //   //   email: this.updateContactInfo.insuredEmailTxt,
  //   //   phone: this.updateContactInfo.insuredPhoneNo,
  //   // });
  // }

  cancelInfo() {
    this.editingInfo = false;

    //reset info
    this.updateInfo = JSON.parse(JSON.stringify(this.lastInfo)); //copy object

    this.effdateChange(this.updateInfo.effectiveDate);
    //also reset the form field values
    this.infoForm.patchValue({
      // effDate: this.updateInfo.effectiveDate,
      effDate: this.datePipe.transform(this.updateInfo.effectiveDate, 'yyyy-MM-dd'),
      limit:
        '$' +
        this.convertStringToComma(this.updateInfo.limit) +
        ' / ' +
        '$' +
        this.convertStringToComma(this.updateInfo.limitAggregate),
      retention: this.convertStringToComma(this.updateInfo.retention),
    });
  }

  ngOnInit() {
    this.ffService.flagChange.subscribe((flags: any) => {
      // console.log('environment.environmentName', environment.environmentName);
      const overridePastEffDateByMCIDs = !flags.overridePastEffDateByMCIDs?.current
        ? flags.overridePastEffDateByMCIDs
        : flags.overridePastEffDateByMCIDs.current;
      this.overridePastEffDateByMCIDs = overridePastEffDateByMCIDs;
      this.setOverridePastEffDateByMCIDsRule();
      // console.log('environment.environmentName', environment.environmentName);
      // console.log('flags', flags);
      const overrideProducerId =
        typeof flags.overrideProducerId != 'string' ? flags.overrideProducerId.current : flags.overrideProducerId;
      if (this.producer != overrideProducerId) {
        this.setProducer(overrideProducerId);
      }
    });

    this.themeService.themeChange.subscribe((theme: Theme) => {
      this.theme = theme;
    });

    if (this.USETESTDATA) {
      //START TEST DATA:( for testing, should come from a real Policy)
      let policyEffDate = new Date();
      let policyExpDate = new Date();
      policyExpDate.setFullYear(policyEffDate.getFullYear() + 1);
      const price: number = 2000;

      this.policyStore = {
        total: price,
        dueToday: price,
        effectiveDate: policyEffDate.toISOString().split('T').shift(),
        expirationDate: policyExpDate.toISOString().split('T').shift(),
        paymentProposal: '1PAY',
      };
      //END TEST DATA
      this.updatePaymentProposal();
    } else {
      //get from api and save in the billing store
      const routeParams = this.route.snapshot.paramMap;
      const policyId = routeParams.get('id');
      this.paramPolicyId = policyId;
      const product = routeParams.get('product');
      this.paramProduct = product == 'ea' ? product : DEFAULT_PRODUCT;
      // console.log('product', product);
      this.contactNumber = this.paramProduct == 'ea' ? constants.contactForCanopy : constants.contactForNationwide;
      // console.log('contactNumber', this.contactNumber);
      // this.theme.agent.phone = this.contactNumber;
      // this.themeService.setTheme(this.theme);

      this.contactInfo = this.contactInfo + this.contactNumber;
      if (this.paramPolicyId == 'unknown' || !this.paramPolicyId) {
        this.policyRequested = true;
      } else {
        this.billingInfoService.getPolicy(policyId, this.paramProduct).subscribe(
          (policyData: PolicyData) => {
            this.agentCode = policyData.responseJSON.policy.agentCd;
          },
          (error) => {
            console.log('error', error);
            this.setIPGPreparing();
          }
        );
        setTimeout(() => {
          this.setIPGPreparing('Loading Quote');

          this.billingInfoService.getPolicy(policyId, this.paramProduct).subscribe(
            (policyData: PolicyData) => {
              this.policyRequested = true;
              this.agentCode = policyData.responseJSON.policy.agentCd;
              this.setProducer(policyData.responseJSON.policy.agentCd);

              this.getLimitandRetentions(policyData);

              let policyEffDate = new Date(policyData.policy.policyEffectiveDate);
              let policyExpDate = new Date(policyData.policy.policyExpirationDate);
              let policyRetroDate = policyData.policy.retroactiveDate
                ? new Date(policyData.policy.retroactiveDate)
                : undefined;

              let tmpPolicyStore: BillingStore = this.billingStoreService.getBillingStore();

              let paymentProposal = policyData?.policy?.paymentPlanCd ? policyData.policy.paymentPlanCd : ''; //no default

              if (
                tmpPolicyStore &&
                tmpPolicyStore?.policyData &&
                tmpPolicyStore?.policyData?.policy?.policyId == this.paramPolicyId
              ) {
                paymentProposal = tmpPolicyStore.paymentProposal; //remember previoulsy chosen paymentProposal
              }

              this.policyStore = {
                total: parseFloat(policyData.policy.policyITDPremium),
                dueToday: parseFloat(policyData.policy.policyITDPremium),
                effectiveDate: policyEffDate.toISOString().split('T').shift(),
                expirationDate: policyExpDate.toISOString().split('T').shift(),
                retroactiveDate: policyRetroDate ? policyRetroDate.toISOString().split('T').shift() : '',
                paymentProposal: paymentProposal ? paymentProposal : '', //no default
                policyData: policyData,
                product: this.paramProduct,
              };

              if (this.TESTING_IPG) {
                this.policyStore.policyData.policy.quoteStatus = 'IPG';
              }

              if (this.policyStore?.policyData?.policy.quoteStatus !== 'QTD') {
                let refreshCnt: number = 0;
                refreshCnt = sessionStorage.getItem('refreshCount_' + policyId)
                  ? Number(localStorage.getItem('refreshCount_' + policyId)) + 1
                  : 0;
                if (refreshCnt > 9) {
                  sessionStorage.setItem('refreshCount_' + policyId, '0');
                  this.stopIPGPreparing();
                  this.showErrorDialog = true;
                  this.pageLoadErrorTitle = 'Error Loading Information';
                  this.pageLoadErrorMessage =
                    'Unable to load policy. Please try again or contact us for support at ' +
                    this.contactNumber +
                    ' or email us at customerservice@archinsurance.com';
                } else {
                  sessionStorage.setItem('refreshCount_' + policyId, refreshCnt.toString());
                  this.setIPGPreparing();
                }
              } else {
                sessionStorage.setItem('refreshCount_' + policyId, '0');
                this.stopIPGPreparing();
              }

              const addressDto = policyData?.policy.addressDto;
              this.addressString = `${addressDto.insuredAddressStreetLine1Txt}, ${addressDto.insuredAddressCityTxt}, ${addressDto.insuredAddressStateCd} ${addressDto.insuredAddressPostalCd}`;

              // this.updatePaymentProposal();

              //let nameParts = this.policyStore?.policyData?.policy?.insuredContactPerson.split(' ');

              // this.updateContactInfo.insuredContactFirstName = nameParts.splice(0, 1)[0]; //remove and return first word
              // this.updateContactInfo.insuredContactLastName = nameParts.join(' '); //what's left is the rest of the name
              // this.updateContactInfo.insuredEmailTxt = this.policyStore?.policyData?.policy?.insuredEmailTxt;
              // this.updateContactInfo.insuredPhoneNo = this.policyStore?.policyData?.policy?.insuredPhoneNo;
              // console.log('this.policyStore.policyData.policy', this.policyStore?.policyData?.policy);
              this.updateInfo.effectiveDate = this.policyStore?.policyData?.policy?.policyEffectiveDate;
              this.updateInfo.limit = this.policyStore?.policyData?.policy?.policyLimit;
              this.updateInfo.limitAggregate = this.policyStore?.policyData?.policy?.limitAggregate;
              this.updateInfo.retention = this.policyStore?.policyData?.policy?.policyAggregateRetention
                ? this.policyStore?.policyData?.policy?.policyAggregateRetention
                : this.policyStore?.policyData?.policy?.quoteCoverageList[0]?.deductibleAmount;

              // this.contactInfoForm.setValue({
              //   fname: this.updateContactInfo.insuredContactFirstName,
              //   lname: this.updateContactInfo.insuredContactLastName,
              //   email: this.updateContactInfo.insuredEmailTxt,
              //   phone: this.updateContactInfo.insuredPhoneNo,
              //   // effDate: this.updateContactInfo.effectiveDate,
              // });

              this.infoForm.setValue({
                // effDate: this.updateInfo.effectiveDate,
                effDate: this.datePipe.transform(this.updateInfo.effectiveDate, 'yyyy-MM-dd'),
                limit:
                  '$' +
                  this.convertStringToComma(this.updateInfo.limit) +
                  ' / ' +
                  '$' +
                  this.convertStringToComma(this.updateInfo.limitAggregate),
                retention: this.convertStringToComma(this.updateInfo.retention),
              });

              const validEffDate = this.isValidDate(this.updateInfo.effectiveDate);
              const isCurrentEffDate = this.isCurrentDate(this.updateInfo.effectiveDate);

              if (!validEffDate || (!isCurrentEffDate && !this.policyIsPastExempt)) {
                this.disableInfoCancel = true;
                if (this.paramProduct !== 'ea') {
                  this.doEditInfo();

                  setTimeout(() => {
                    if (!validEffDate || !isCurrentEffDate) {
                      let effDateFControl = this.infoForm.get('effDate');
                      effDateFControl.markAsTouched();
                      effDateFControl.markAsDirty();
                      effDateFControl.updateValueAndValidity();
                    }
                  }, 100);
                } else {
                  this.mustGoBackToReferrer = true;
                }
              }

              // if (!this.updateContactInfo.insuredContactFirstName || !this.updateContactInfo.insuredContactLastName) {
              //   this.disableContactInfoCancel = true;

              //   if (this.paramProduct !== 'ea') {
              //     this.doEditContactInfo();

              //     //setTimeout(() => {
              //     //must have last name
              //     //   if (!this.updateContactInfo.insuredContactLastName) {
              //     //  //   let lnameFControl = this.contactInfoForm.get('lname');
              //     //     lnameFControl.markAsTouched();
              //     //     lnameFControl.markAsDirty();
              //     //     lnameFControl.updateValueAndValidity();
              //     //   }

              //     //must have first name
              //     //   if (!this.updateContactInfo.insuredContactFirstName) {
              //     //     let fnameFControl = this.contactInfoForm.get('fname');
              //     //     fnameFControl.markAsTouched();
              //     //     fnameFControl.markAsDirty();
              //     //     fnameFControl.updateValueAndValidity();
              //     //   }
              //     // }, 100);
              //   } else {
              //     this.mustGoBackToReferrer = true;
              //   }
              // }

              if (this.policyStore?.policyData?.policy?.quoteCoverageList) {
                this.coverageLiteralName =
                  this.policyStore?.policyData?.policy?.quoteCoverageList[0].coverageCodeInfo.coverageLiteralName.replace(
                    'Miscellaneous/Specified',
                    'Miscellaneous'
                  );
              }
              // console.log(
              //   'this.policyStore?.policyData?.referenceData?.product?.productOffering',
              //   this.policyStore?.policyData?.referenceData?.product?.productOffering
              // );

              if (this.policyStore?.policyData?.referenceData?.product?.productOffering) {
                const productMapping = {
                  PRDCNP: 'Management Liability', //'Arch Corporate Canopy Policy Private Company Management Liability & Crime Insurance',
                  MSPPL: 'Miscellaneous/Specified Professional Liability',
                  MSSPL: 'Miscellaneous/Specified Professional Liability',
                };
                let productOffering = this.policyStore.policyData.referenceData.product.productOffering;
                // console.log('productOffering', productOffering);
                this.coverageLiteralName = productMapping[productOffering]
                  ? productMapping[productOffering]
                  : productOffering;
              }

              // console.log('queryParamMap', this.route.snapshot.queryParamMap.get('redirectUrl'));
              this.policyStore.redirectUrl = this.getRedirectUrl(); //Set redirectUrl into store to be used after successful payment.

              // console.log('this.policyStore.redirectUrl', this.policyStore.redirectUrl, atob(this.policyStore.redirectUrl));

              this.billingStoreService.setBillingStore(this.policyStore);

              this.updatePaymentProposal();
            },
            (error) => {
              console.log('error', error);
              this.setIPGPreparing();
            }
          );
        });
      }
    }
  }

  getRedirectUrl() {
    let ru = this.route.snapshot.queryParamMap.get('redirectUrl');
    return ru ? ru : this.theme.redirectUrl;
  }

  convertStringToComma(number: any) {
    return number ? number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : number;
  }
  inverseCommaToString(number: any) {
    return number.toString().replace(/,/g, '');
  }
  removeCurrencySign(number: any) {
    return number.toString().replace(/\$/g, '');
  }

  getLimitandRetentions(policyData: PolicyData) {
    let agentCd = policyData?.responseJSON?.policy?.agentCd;
    let productOffering = policyData?.responseJSON?.policy?.productOffering;
    let product = this.paramProduct == DEFAULT_PRODUCT ? 'PL' : this.paramProduct;
    this.billingInfoService.getLimitandRetention(agentCd, product, productOffering).subscribe(
      (data: any) => {
        this.limitAndRetentionFetched = true;
        if (data && data[0]) {
          this.limitList = data[0]['policyLmt_aggLmt'];
          this.retentionList = data[0]['retentions'];
        } else {
          this.limitList = [];
          this.retentionList = [];
        }
      },
      (error: any) => {
        this.limitList = [];
        this.retentionList = [];
      }
    );
  }

  getPaymentSchedule() {
    for (let x in this.paymentPlans[this.paramProduct]) {
      let plan = this.paymentPlans[this.paramProduct][x];

      // console.log('plan', plan);

      this.billingInfoService
        .getPaymentSchedule(
          this.policyStore.total,
          this.policyStore.effectiveDate,
          this.policyStore.expirationDate,
          plan.code
        )
        .subscribe((data) => {
          // console.log('data', data);
          let installments = [];

          if (
            data?.responseTransactionSchedule?.TransactionResponse?.TransactionDetail[0]
              ?.InstallmentScheduleSummarybyDueDate
          ) {
            // console.log('data.responseTransactionSchedule.TransactionResponse.TransactionDetail', data.responseTransactionSchedule.TransactionResponse.TransactionDetail[0]?.InstallmentScheduleSummarybyDueDate);

            let paymentSummary =
              data.responseTransactionSchedule.TransactionResponse.TransactionDetail[0]
                ?.InstallmentScheduleSummarybyDueDate;

            for (let i = 0; i < paymentSummary.length; i++) {
              const payment = paymentSummary[i];
              // console.log('payment', payment);
              for (let n = 0; n < payment.TransactionReceivableSummary.length; n++) {
                const installment = payment.TransactionReceivableSummary[n];
                let billDate = payment.BillDueDate.split('-');
                billDate.pop(); //remove the last item which is a time (causes date parsing to fail)
                installment.BillDueDate = billDate;
                installment.BillDueDateObj = new Date(installment.BillDueDate);
                installment.amount = installment.Balance; // + installment.CommissionAmount; //have to total the commission and balance to get correct payment amount
                installments.push(installment);
              }
            }

            //Sort the array by sequence
            installments.sort((a, b) => {
              const aNo = parseInt(a.InstallmentNo);
              const bNo = parseInt(b.InstallmentNo);
              return aNo - bNo;
            });

            //in HTML loop installments

            // this.installments = installments;
            plan.installments = installments;

            // console.log('installments', installments);

            if (this.policyStore.paymentProposal != '1PAY') {
              this.updatePaymentProposal();
            }
          } else {
            console.warn(
              'response missing responseTransactionSchedule.TransactionResponse.TransactionDetail[0]?.InstallmentScheduleSummarybyDueDate'
            );
          }
        });
    }
  }

  updatePaymentProposal() {
    this.policyStore.premiumAmount = 0;
    this.policyStore.dueToday = 0;
    this.policyStore.feesAmount = 0;
    this.policyStore.total = 0;

    // console.log('this.paramProduct', this.paramProduct);
    // console.log('this.policyStore.paymentProposal', this.policyStore.paymentProposal);
    // console.log('this.paymentPlans[this.paramProduct][this.policyStore.paymentProposal]', this.paymentPlans[this.paramProduct][this.policyStore.paymentProposal]);

    this.installments = this.paymentPlans[this.paramProduct][this.policyStore.paymentProposal]?.installments || [];

    if (this.policyStore.paymentProposal != '1PAY' && this.installments.length) {
      this.policyStore.premiumAmount = this.installments[0].amount; //due today is the amount in the first installment of the payment schedule
    } else {
      this.policyStore.premiumAmount = parseFloat(this.policyStore.policyData.policy.policyITDPremium);
    }

    this.policyStore.dueToday = this.policyStore.premiumAmount;

    if (this.policyStore.policyData?.policy?.TotTaxFeesAndSurcharge) {
      this.policyStore.feesAmount = parseFloat(this.policyStore.policyData.policy.TotTaxFeesAndSurcharge);
    } else if (this.policyStore?.policyData?.policy?.taxFeesSurchargeList) {
      //add the fees to this.policyStore.dueToday
      for (let i = 0; i < this.policyStore.policyData.policy.taxFeesSurchargeList.length; i++) {
        const fee = this.policyStore.policyData.policy.taxFeesSurchargeList[i];
        if (fee.hasOwnProperty('premiumAmt')) {
          this.policyStore.feesAmount += parseFloat(fee.premiumAmt);
        }
      }
    }

    this.policyStore.dueToday += this.policyStore.feesAmount;

    this.policyStore.total =
      parseFloat(this.policyStore.policyData.policy.policyITDPremium) + this.policyStore.feesAmount;

    // this.policyStore.policyData.policy.Payment_Plan_Cd = this.policyStore.paymentProposal;
    this.policyStore.policyData.responseJSON.policy.Payment_Plan_Cd = this.policyStore.paymentProposal;

    this.billingStoreService.setBillingStore(this.policyStore);

    this.bindForm.reset();
  }

  bindForm = this.fb.group({
    affirm: ['', Validators.required],
    autopay: ['', Validators.required],
    legal: ['', Validators.required],
    legal2: ['', Validators.required],
  });

  setBindOnQuote(policyData: any) {
    this.policyStore.policyData = policyData;
    console.info(`%c Policy Number ${this.policyStore.policyData.policy.polNbr}`, 'font-weight: bold; color: #0057b8');
    this.billingStoreService.setBillingStore(this.policyStore);
  }

  updateProducer() {
    if (this.policyStore.policyData.referenceData.branch.agencyUnderwriterEmail != this.userProfile.email) {
      this.policyStore.policyData.referenceData.branch.agencyUnderwriterEmail = this.userProfile.email;
      this.policyStore.policyData.referenceData.branch.agencyUnderwriterName = `${this.userProfile.given_name} ${this.userProfile.family_name}`;
      this.policyStore.policyData.referenceData.branch.agentAssignedUW = this.userProfile.email;
      this.policyStore.policyData.responseJSON.policy.underwriterEmailAddress = this.userProfile.email;

      this.billingStoreService.setBillingStore(this.policyStore);

      this.billingInfoService.updatePolicy(this.policyStore.policyData).subscribe((data) => {
        if (data && data.returnCode == 'SUCCESS') {
          //nothing
        } else {
          //nothing yet
        }
      });
    }
  }

  openBindDialog(): void {
    this.bindDialog = true;
    // this.updateProducer();//sending this in on postProcessing only now.
  }

  goToPayment() {
    if (!this.BYPASSBINDCALL && this.policyStore.policyData.policy.quoteStatus != 'BND') {
      //run getPolicy and check for bound status
      this.billingInfoService
        .getPolicy(this.policyStore.policyData.policy.policyId)
        .subscribe((policyData: PolicyData) => {
          if (policyData.policy.quoteStatus == 'BND') {
            this.bindFinished = true;
            this.setBindOnQuote(policyData);
            this.router.navigate(['payer-info']);
          } else {
            //policy not bound, must bind
          }
        });
    } else if (this.BYPASSBINDCALL) {
      this.bindFinished = true;
      this.router.navigate(['payer-info']);
    } else {
      this.bindFinished = true;
      this.setBindOnQuote(this.policyStore.policyData);
      this.router.navigate(['payer-info']);
    }
  }
  bindingMessages: string[] = [
    'Your request is being processed.',
    'Saving updated information to your policy.',
    'Updating and synchronizing our records.',
    'Binding your policy to be ready to issue.',
    'Polishing up final details.',
    'This should be completed soon.',
    'Your time is very important to us.',
    'Your request is still being processed, please wait.',
    'We should be finishing up shortly. Thank you for your patience.',
  ];
  doingBind: boolean = false;
  bindFinished: boolean = true;
  bindTimedOut: boolean = false;
  bindError: string;

  doBind(): void {
    this.bindFinished = false;
    this.bindDialog = false;
    this.doingBind = true;
    this.bindError = '';

    // this.BYPASSBINDCALL = true;//uncomment for testing

    // call bind then refirect to payment
    if (!this.BYPASSBINDCALL) {
      this.billingInfoService.bindPolicy(this.policyStore).subscribe(
        (data) => {
          console.log('bind response data', data);
          if (data && data.status == 'BOUND' && data?.detail?.policyNumber) {
            this.goToPayment();
          } else {
            this.doingBind = false;
            this.bindTimedOut = true;
            this.bindError = 'There was a problem binding this policy. Please contact our support for help.';
            console.error('Failed to Bind', data);
            //TODO some error handling here
            // this.router.navigate(['payer-info']);
          }
        },
        (data) => {
          this.bindError = 'There was a problem binding this policy. Please contact our support for help.';
          console.error('Failed to Bind', data);
        }
      );
    } else {
      this.goToPayment();
    }
  }

  bindTimeOutChange($event: any) {
    console.log('bind timed out $event', $event);
  }

  downloadQuote() {
    if (!this.downloading) {
      this.downloading = true;
      this.billingInfoService.downloadQuote(this.policyStore).subscribe((resultBytes) => {
        var blob = new Blob([resultBytes], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `arch-quote-${this.policyStore.policyData.policy.policyId}.pdf`;
        link.click();

        this.downloading = false;
      });
    }
  }

  private preparingQuoteTimer: ReturnType<typeof setTimeout>;
  preparingTitle: string = 'Preparing Quote';
  stopIPGPreparing(): void {
    clearTimeout(this.preparingQuoteTimer);
    this.isPreparingQuote = false;
  }
  setIPGPreparing(title: string = 'Preparing Quote'): void {
    this.preparingTitle = title;
    clearTimeout(this.preparingQuoteTimer);
    //reload page every 10 seconds until quote is no longer in progress.
    this.isPreparingQuote = true;
    this.preparingQuoteTimer = setTimeout(() => {
      this.refreshPage();
    }, 10000);
  }

  refreshPage() {
    location.reload();
  }

  preparingMessages: string[] = [
    'Your quote is being prepared.',
    'Your quote will be ready shortly. Thank you for your patience.',
  ];
  isPreparingQuote: boolean = false;
  prepareQuoteTimedOut: boolean = false;
  preparingError: string;

  prepareTimeOutChange($event: any) {
    console.log('preparing quote timed out $event', $event);
    location.reload();
    //refresh page
  }
}

<ng-container *ngIf="policyRequested && (!policyStore?.policyData?.policy?.policyId || paramPolicyId == 'unknown')">
  <apt-template-nested-state state="info" title="Invalid Policy">
    <ng-container message>
      <p>
        <ng-container *ngIf="paramPolicyId == 'unknown'">
          <p>No policy id was provided. To access the payment portal, please follow a valid link.</p>
        </ng-container>

        <ng-container *ngIf="paramPolicyId != 'unknown'">
          <p>Sorry, the provided policy id is invalid. Please try again or contact us for support:</p>

          <ng-template *ngTemplateOutlet="contactRef"></ng-template>
        </ng-container>
      </p>
    </ng-container>
  </apt-template-nested-state>
</ng-container>

<ng-container *ngIf="!policyRequested || policyStore?.policyData?.policy?.policyId">
  <app-quote-header
    [insuredName]="policyStore?.policyData?.policy.addressDto.insuredName"
    [address]="addressString"
    [policyType]="coverageLiteralName"
    [issuingCompany]="
      policyStore?.policyData?.policy.issuingCompany == 1
        ? 'Arch Insurance Company'
        : policyStore?.policyData?.policy.issuingCompany
    "
  >
  </app-quote-header>

  <apt-content-box>
    <div fxLayout="row" fxLayoutAlign="space-between stretch" class="content-container apt-negate-padding">
      <div fxFlex fxFill class="content">
        <!-- <form [formGroup]="contactInfoForm" (ngSubmit)="submitContactForm()" aptForm>
          <apt-h2 underline>
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="70">Contact Information</div>
              <div
                fxFlex
                fxLayout="row"
                fxLayoutAlign="end center"
                class="edit-contact-info"
                *ngIf="policyStore?.policyData?.policy.quoteStatus === 'QTD' && paramProduct != 'ea' && editable"
              >
                <apt-button *ngIf="!editingContact && !editingDisabled" [tertiary]="true" (click)="doEditContactInfo()">
                  Edit Contact
                </apt-button>
              </div>
            </div>
          </apt-h2>

          <div fxLayout="row" class="info-row">
            <div fxFlex fxLayout="column" fxLayoutAlign="start start">
              <ng-container *ngIf="!editingContact">
                <apt-text-label label="First Name">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="updateContactInfo.insuredContactFirstName"
                  ></apt-text-waiter>
                </apt-text-label>
              </ng-container>
              <ng-container *ngIf="editingContact">
                <apt-form-row>
                  <apt-input label="First Name" [aptInput]="fnameInput">
                    <input #fnameInput type="text" placeholder="First Name" formControlName="fname" required />
                  </apt-input>
                </apt-form-row>
              </ng-container>
            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="start start">
              <ng-container *ngIf="!editingContact">
                <apt-text-label label="Last Name">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="updateContactInfo.insuredContactLastName"
                  ></apt-text-waiter>
                </apt-text-label>
              </ng-container>

              <ng-container *ngIf="editingContact">
                <apt-form-row>
                  <apt-input label="Last Name" [aptInput]="lnameInput">
                    <input #lnameInput type="text" placeholder="Last Name" formControlName="lname" required />
                  </apt-input>
                </apt-form-row>
              </ng-container>
            </div>
          </div>

          <div fxLayout="row" class="info-row">
            <div fxFlex fxLayout="column" fxLayoutAlign="start start">
              <ng-container *ngIf="!editingContact">
                <apt-text-label label="Email Address">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="policyStore?.policyData?.policy?.insuredEmailTxt"
                  ></apt-text-waiter>
                </apt-text-label>
              </ng-container>
              <ng-container *ngIf="editingContact">
                <apt-form-row>
                  <apt-input label="Email Address" [aptInput]="emailInput">
                    <input #emailInput type="email" placeholder="Email Address" formControlName="email" required />
                  </apt-input>
                </apt-form-row>
              </ng-container>
              <apt-inform [style.margin-top]="!editingContact ? '8px' : ''"
                >This email will be used to create your online account for managing your policy.</apt-inform
              >
            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="start start">
              <ng-container *ngIf="!editingContact">
                <apt-text-label label="Phone Number">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="policyStore?.policyData?.policy?.insuredPhoneNo"
                  ></apt-text-waiter>
                </apt-text-label>
              </ng-container>
              <ng-container *ngIf="editingContact">
                <apt-form-row>
                  <apt-input label="Phone Number" [aptInput]="phoneInput" [errorMsg]="phoneMessages.error.pattern">
                    <input #phoneInput type="text" placeholder="Phone Number" formControlName="phone" required />
                  </apt-input>
                </apt-form-row>
              </ng-container>
            </div>
          </div>

          <div
            *ngIf="editingContact"
            class="nested-form-button-row"
            fxLayout="row"
            fxLayoutAlign="end center"
            fxLayoutGap="8px"
          >
            <apt-button
              *ngIf="!disableContactInfoCancel"
              [tertiary]="true"
              (click)="cancelContactInfo()"
              [disabled]="savingContactInfo"
            >
              Cancel
            </apt-button>
            <apt-button [tertiary]="true" type="submit" [disabled]="savingContactInfo || !contactInfoForm.valid">
              {{ savingContactInfo ? 'Saving...' : 'Save' }}
            </apt-button>
          </div>
        </form> -->
        <hr *ngIf="editingContact" />
        <form [formGroup]="infoForm" (ngSubmit)="submitInfoForm()" aptForm>
          <apt-h2 underline
            ><div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="70">Policy Information</div>
              <div
                fxFlex
                fxLayout="row"
                fxLayoutAlign="end center"
                class="edit-contact-info"
                *ngIf="policyStore?.policyData?.policy.quoteStatus === 'QTD' && paramProduct != 'ea'"
              >
                <apt-button *ngIf="!editingInfo && !editingDisabled" [tertiary]="true" (click)="doEditInfo()">
                  Edit Information
                </apt-button>
              </div>
            </div>
          </apt-h2>

          <div fxLayout="row" class="info-row">
            <div fxFlex fxLayout="column" fxLayoutAlign="center start">
              <apt-text-label label="Class of Business">
                <apt-text-waiter
                  [text]="policyStore?.policyData?.referenceData.classOfBusiness.insuredClassofBusinessName"
                >
                </apt-text-waiter>
              </apt-text-label>
            </div>
          </div>

          <ng-container *ngIf="paramProduct != 'ea'">
            <div fxLayout="row" class="info-row">
              <div fxFlex fxLayout="column" fxLayoutAlign="center start">
                <apt-text-label label="Professional Liability Definition">
                  <apt-text-waiter
                    [text]="policyStore?.policyData?.referenceData.classOfBusiness.insuredClassofBusinessDesc"
                  >
                    <!-- otherInsuredClassofBusinessDesc -->
                  </apt-text-waiter>
                </apt-text-label>
              </div>
            </div>
          </ng-container>

          <div fxLayout="row" class="info-row">
            <div fxFlex fxLayout="column" fxLayoutAlign="center start">
              <ng-container *ngIf="!editingInfo">
                <apt-text-label label="Effective Date">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="policyStore?.policyData?.policy.policyEffectiveDate"
                  ></apt-text-waiter>
                </apt-text-label>
              </ng-container>
              <ng-container *ngIf="editingInfo">
                <apt-form-row>
                  <apt-input label="Effective Date" [aptInput]="effDateInput">
                    <input
                      (change)="onChangeEffDate($event)"
                      (focus)="effDateInput.showPicker()"
                      #effDateInput
                      type="date"
                      placeholder="Effective Date"
                      formControlName="effDate"
                      required
                    />
                  </apt-input>
                </apt-form-row>
              </ng-container>
            </div>

            <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Effective Date">
              <apt-text-waiter [fallbackTime]="60000" [text]="policyStore.effectiveDate | date: 'MM/dd/yyyy'"></apt-text-waiter>
            </apt-text-label>
          </div> -->

            <div fxFlex fxLayout="column" fxLayoutAlign="center start">
              <apt-text-label label="Expiration Date">
                <apt-text-waiter
                  [fallbackTime]="60000"
                  [text]="policyStore?.policyData?.policy.policyExpirationDate"
                ></apt-text-waiter>
              </apt-text-label>
            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="center start">
              <apt-text-label
                label="Retroactive Date"
                *ngIf="paramProduct != 'ea' && policyStore?.policyData?.policy.retroactiveDate"
              >
                <apt-text-waiter
                  [fallbackTime]="60000"
                  [text]="policyStore?.policyData?.policy.retroactiveDate"
                ></apt-text-waiter>
              </apt-text-label>
            </div>
          </div>

          <ng-template #infoButtons>
            <div
              *ngIf="editingInfo"
              class="nested-form-button-row"
              fxLayout="row"
              fxLayoutAlign="end center"
              fxLayoutGap="8px"
            >
              <apt-button *ngIf="!disableInfoCancel" [tertiary]="true" (click)="cancelInfo()" [disabled]="savingInfo">
                Cancel
              </apt-button>

              <apt-button [tertiary]="true" type="submit" [disabled]="savingInfo || !infoForm.valid">
                {{ savingInfo ? 'Saving...' : 'Save' }}
              </apt-button>
            </div>
          </ng-template>

          <!-- <ng-template *ngTemplateOutlet="infoButtons"></ng-template> -->

          <ng-container *ngIf="paramProduct != 'ea'">
            <!-- <apt-h2 underline>Limits/Retentions</apt-h2> -->

            <div fxLayout="row" class="info-row">
              <div fxFlex fxLayout="column" fxLayoutAlign="center start">
                <ng-container *ngIf="!editingInfo">
                  <apt-text-label label="Each Claim Limit">
                    <apt-text-waiter
                      [fallbackTime]="60000"
                      [text]="policyStore?.policyData?.policy.policyLimit | currency"
                    ></apt-text-waiter>
                  </apt-text-label>
                </ng-container>
                <ng-container *ngIf="editingInfo && limitAndRetentionFetched">
                  <apt-form-row>
                    <apt-select label="Each Claim Limit / Aggregate Limit" [aptSelect]="aptSelect">
                      <select #aptSelect formControlName="limit">
                        <option *ngFor="let limit of limitList" [value]="limit">{{ limit }}</option>
                      </select>
                    </apt-select>
                  </apt-form-row>
                </ng-container>
              </div>

              <div fxFlex fxLayout="column" fxLayoutAlign="center start">
                <ng-container *ngIf="!editingInfo">
                  <apt-text-label label="Aggregate Limit">
                    <apt-text-waiter
                      [text]="policyStore?.policyData?.policy.limitAggregate | currency"
                    ></apt-text-waiter>
                  </apt-text-label>
                </ng-container>
              </div>

              <div fxFlex fxLayout="column" fxLayoutAlign="center start">
                <ng-container *ngIf="!editingInfo">
                  <apt-text-label label="Retention">
                    <apt-text-waiter
                      [text]="
                        (policyStore?.policyData?.policy?.policyAggregateRetention
                          ? policyStore?.policyData?.policy?.policyAggregateRetention
                          : policyStore?.policyData?.policy?.quoteCoverageList[0]?.deductibleAmount
                        ) | currency
                      "
                    >
                    </apt-text-waiter>
                  </apt-text-label>
                </ng-container>
                <ng-container *ngIf="editingInfo && limitAndRetentionFetched">
                  <apt-form-row>
                    <apt-select label="Retention" [aptSelect]="aptRSelect">
                      <select #aptRSelect formControlName="retention">
                        <option *ngFor="let retention of retentionList" [value]="retention">{{ retention }}</option>
                      </select>
                    </apt-select>
                  </apt-form-row>
                </ng-container>
              </div>
            </div>

            <ng-template *ngTemplateOutlet="infoButtons"></ng-template>
          </ng-container>
        </form>
        <div style="text-align: right">
          <apt-inform state="error" *ngIf="mustGoBackToReferrer"
            >Payment/Bind cannot be processed for a quote with an effective date in the past. Please
            <span class="clickable" (click)="goBackToReferrer()">go back</span> to change the effective date.
          </apt-inform>
        </div>

        <apt-button-footer>
          <ng-container buttonFooterRight>
            <apt-button
              *ngIf="policyStore?.policyData?.policy.quoteStatus === 'QTD'"
              secondary
              [disabled]="editingContact || editingInfo"
              (click)="downloadQuote()"
              [aptTooltip]="
                editingContact || editingInfo
                  ? 'To download quote, please ' + (disableContactInfoCancel ? '' : 'cancel or ') + 'save information.'
                  : ''
              "
              [loading]="downloading"
              [loadingExpectedTime]="9000"
              >{{ downloading ? 'Downloading Quote' : 'Download Quote' }}</apt-button
            >

            <apt-button
              [disabled]="editingContact || editingInfo || mustGoBackToReferrer || !policyStore.paymentProposal"
              [primary]="true"
              (click)="openBindDialog()"
              [aptTooltip]="
                editingContact || editingInfo
                  ? 'To proceed, please ' + (disableContactInfoCancel ? '' : 'cancel or ') + 'save information.'
                  : mustGoBackToReferrer
                  ? 'Invalid quote.'
                  : !policyStore.paymentProposal
                  ? 'Please select a payment schedule to proceed.'
                  : ''
              "
              tooltipPlacement="top"
              tooltipMaxWidth="matchElm"
              >{{ BYPASSBINDCALL ? 'Proceed to Payment' : 'Bind' }}</apt-button
            >

            <ng-container *ngIf="policyStore?.policyData?.policy.quoteStatus === 'BND'">
              <apt-button
                [disabled]="editingContact || editingInfo || !policyStore.paymentProposal"
                [primary]="true"
                (click)="goToPayment()"
                [aptTooltip]="
                  editingContact || editingInfo
                    ? 'To proceed, please ' + (disableContactInfoCancel ? '' : 'cancel or ') + 'save information.'
                    : !policyStore.paymentProposal
                    ? 'Please select a payment schedule to proceed.'
                    : ''
                "
                tooltipPlacement="top"
                tooltipMaxWidth="matchElm"
              >
                Proceed to Payment
              </apt-button>
            </ng-container>

            <!-- <ng-container *ngIf="!policyStore?.policyData?.policy.quoteStatus">
              <apt-button [primary]="true" disabled="true">
                <apt-text-waiter [fallbackTime]="60000" [text]="policyStore?.policyData?.policy.quoteStatus" fallbackText="Quote Not Ready"></apt-text-waiter>
              </apt-button>
            </ng-container> -->
          </ng-container>
        </apt-button-footer>
      </div>

      <div fxFlex="350px" class="right-panel content">
        <payment-summary
          #paymentSummary
          [refreshInstallments]="true"
          [prompt]="promptPaymentSchedule"
          [editable]="true"
          [policyStore]="policyStore"
          [product]="paramProduct"
        ></payment-summary>

        <contact-box></contact-box>

        <div style="margin-top: 16px" *ngIf="contactInfoError">
          <apt-inform state="error">{{ contactInfoError }}</apt-inform>
        </div>

        <div style="margin-top: 16px" *ngIf="infoError">
          <apt-inform state="error">{{ infoError }}</apt-inform>
        </div>
      </div>
    </div>
  </apt-content-box>
</ng-container>

<div style="display: none">
  <ng-template #contactRef>
    <div class="contact-list">
      <div>Arch Customer Support</div>
      <div *ngIf="agentCode != 18089">customerservice@archinsurance.com</div>
      <div *ngIf="agentCode != 18089">{{ contactNumber }}</div>
    </div>
  </ng-template>
</div>

<!-- BIND POPUP -->
<form [formGroup]="bindForm" (ngSubmit)="doBind()">
  <apt-dialog [title]="BYPASSBINDCALL ? 'Confirmation' : 'Bind Confirmation'" modal="true" [(open)]="bindDialog">
    <ng-container dialogBody>
      <p>
        Before proceeding<span *ngIf="!BYPASSBINDCALL"> to bind</span>, please review and select the required fields
        below:
      </p>

      <div>
        <apt-checkbox
          label="You affirm and certify that all the information in this application herein is complete, true and correct to the best of your knowledge and belief."
        >
          <input type="checkbox" name="affirm" formControlName="affirm" required />
        </apt-checkbox>
      </div>

      <div style="margin-top: 8px">
        <apt-checkbox
          label="You understand and agree that a third party payment processor (the “Payment Processor”) processes the payments for this Policy.  Our current Payment Processor is Chase Paymentech, and your payments are processed by Chase. Arch Insurance Group Inc. does not control and is not liable for the security or performance of the Payment Processor."
        >
          <input type="checkbox" name="legal" formControlName="legal" required />
        </apt-checkbox>
      </div>

      <div style="margin-top: 8px">
        <apt-checkbox
          label="When you provide payment information, you represent and warrant that the information is accurate and that you are authorized to use the payment method provided."
        >
          <input type="checkbox" name="legal2" formControlName="legal2" required />
        </apt-checkbox>
      </div>

      <div style="margin-top: 8px">
        <apt-checkbox label="You agree to sign up for autopay.">
          <input type="checkbox" name="autopay" formControlName="autopay" required />
        </apt-checkbox>
      </div>
    </ng-container>
    <ng-container dialogFooter>
      <apt-button-footer>
        <ng-container buttonFooterLeft>
          <apt-button secondary="default" (click)="bindDialog = false">Cancel</apt-button>
        </ng-container>

        <ng-container buttonFooterRight>
          <apt-button
            [primary]="true"
            type="submit"
            [disabled]="!bindForm.valid || doingBind"
            [title]="bindForm.valid ? '' : 'Please agree to the required options.'"
          >
            <ng-container *ngIf="doingBind">Please wait <apt-text-waiter></apt-text-waiter> </ng-container>
            <ng-container *ngIf="BYPASSBINDCALL && !doingBind">Confirm and Proceed</ng-container>
            <ng-container *ngIf="!BYPASSBINDCALL && !doingBind">Bind and proceed to payment</ng-container>
          </apt-button>
        </ng-container>
      </apt-button-footer>
    </ng-container>
  </apt-dialog>
</form>
<!-- END BIND POPUP -->

<apt-dialog title="" modal="true" [(open)]="doingBind" width="670px" [showCloseX]="false">
  <ng-container dialogBody>
    <apt-waiter
      [evaluate]="bindFinished"
      [title]="BYPASSBINDCALL ? 'Preparing Policy' : 'Binding Policy'"
      [rollingText]="bindingMessages"
      [rollingWait]="4000"
      [repeat]="false"
      [timeout]="0"
      [(timedOut)]="bindTimedOut"
      [error]="bindError"
      (timedOutChange)="bindTimeOutChange($event)"
      [contactInfo]="contactInfo"
    ></apt-waiter>
  </ng-container>
</apt-dialog>

<apt-dialog title="" modal="true" [(open)]="isPreparingQuote" width="670px" [showCloseX]="false">
  <ng-container dialogBody>
    <apt-waiter
      *ngIf="agentCode != 18089"
      [evaluate]="policyStore?.policyData?.policy.quoteStatus === 'QTD'"
      [title]="preparingTitle"
      [rollingText]="preparingMessages"
      timeout="120000"
      [rollingWait]="5000"
      [repeat]="false"
      [(timedOut)]="prepareQuoteTimedOut"
      [error]="preparingError"
      (timedOutChange)="prepareTimeOutChange($event)"
    ></apt-waiter>
    <apt-waiter
      *ngIf="agentCode == 18089"
      [evaluate]="policyStore?.policyData?.policy.quoteStatus === 'QTD'"
      [title]="preparingTitle"
      [rollingText]="preparingMessages"
      [contactInfo]="emptyContactMsg"
      timeout="120000"
      [rollingWait]="5000"
      [repeat]="false"
      [(timedOut)]="prepareQuoteTimedOut"
      [error]="preparingError"
      (timedOutChange)="prepareTimeOutChange($event)"
    ></apt-waiter>

    <p class="in-progress-refresh-message">
      This page will automatically refresh. Or you can
      <span class="clickable" (click)="refreshPage()">click here to refresh now</span>.
    </p>
  </ng-container>
</apt-dialog>

<apt-dialog [title]="pageLoadErrorTitle" modal="true" [(open)]="showErrorDialog" [showCloseX]="false">
  <ng-container dialogBody>
    <p>{{ pageLoadErrorMessage }}</p>
  </ng-container>
</apt-dialog>

<apt-dialog
  title=""
  modal="true"
  [(open)]="savingContactInfo || savingInfo"
  width="670px"
  [showCloseX]="contactInfoError || infoError"
>
  <ng-container dialogBody>
    <apt-waiter
      [evaluate]="false"
      title="Updating"
      [rollingText]="updatingMessages"
      timeout="0"
      [rollingWait]="5000"
      [repeat]="true"
      [error]="contactInfoError || infoError"
    >
    </apt-waiter>
  </ng-container>
</apt-dialog>

<ng-container *ngIf="policyRequested && (!policyStore?.id || paramPolicyId == 'unknown')">
  <apt-template-nested-state state="info" title="Invalid Policy">
    <ng-container message>
      <p>
        <ng-container *ngIf="paramPolicyId == 'unknown'">
          <p>No policy id was provided. To access the payment portal, please follow a valid link.</p>
        </ng-container>

        <ng-container *ngIf="paramPolicyId != 'unknown'">
          <p>paramPolicyId: {{ paramPolicyId }}</p>
          <p>policyStore?.id: {{ policyStore?.id }}</p>
          <p>Sorry, the provided policy id is invalid. Please try again or contact us for support:</p>

          <contact-box [overrideTheme]="d2cTheme" [phoneType]="'phoneD2C'"></contact-box>
        </ng-container>
      </p>
    </ng-container>
  </apt-template-nested-state>
</ng-container>

<ng-template #paymentButton>
  <dtc-button
    [disabled]="editingContact || editingInfo || mustGoBackToReferrer || !policyStore.paymentProposal"
    [primary]="true"
    [aptTooltip]="
      editingContact || editingInfo
        ? 'To proceed, please ' + (disableContactInfoCancel ? '' : 'cancel or ') + 'save information.'
        : mustGoBackToReferrer
        ? 'Invalid quote.'
        : !policyStore.paymentProposal
        ? 'Please select a payment schedule to proceed.'
        : ''
    "
    tooltipPlacement="top"
    tooltipMaxWidth="matchElm"
    [allowDisabledClick]="true"
    (click)="goToConfirm()"
  >
    Proceed to Payment</dtc-button
  >
</ng-template>

<ng-template #contactButtons let-location="location">
  <div class="edit-contact-info" *ngIf="policyStore?.policyData?.policy.quoteStatus === 'QTD' && paramProduct != 'ea'">
    <!-- <dtc-button
      fxHide.lt-md
      [icon]="faEdit"
      *ngIf="!editingContact && !editingDisabled && location == 'above'"
      secondary
      (click)="doEditContactInfo()"
    >
      Edit Contact
    </dtc-button> -->

    <!-- <dtc-button
      fxFlex.lt-md="100"
      fxHide.gt-sm
      [icon]="faEdit"
      *ngIf="!editingContact && !editingDisabled && location == 'below'"
      secondary
      (click)="doEditContactInfo()"
    >
      Edit Contact
    </dtc-button> -->

    <dtc-button
      fxFlex.lt-md="100"
      *ngIf="editingContact && !disableContactInfoCancel && location == 'below'"
      secondary
      (click)="cancelContactInfo()"
      [disabled]="savingContactInfo"
    >
      Cancel
    </dtc-button>
    <dtc-button
      fxFlex.lt-md="100"
      [icon]="faEdit"
      primary
      secondary
      type="submit"
      [disabled]="savingContactInfo || !contactInfoForm.valid"
      *ngIf="editingContact && location == 'below'"
    >
      {{ savingContactInfo ? 'Saving...' : 'Save' }}
    </dtc-button>
  </div>
</ng-template>

<ng-container *ngIf="!policyRequested || policyStore?.id">
  <dtc-page-content ngClass="{'progressive': theme.name == 'progressive'}">
    <ng-container main>
      <form [formGroup]="contactInfoForm" (ngSubmit)="submitContactForm()" aptForm>
        <apt-h1 class="underline-title">
          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start center">
            <div fxFlex fxFlex.gt-sm="70" class="theme-title" [style.color]="theme?.primaryColor">Quote Details</div>

            <div fxFlex fxLayout="row" fxHide.lt-md fxLayoutAlign.gt-sm="end center">
              <ng-template *ngTemplateOutlet="contactButtons; context: { location: 'above' }"></ng-template>
            </div>
          </div>
        </apt-h1>

        <!-- <apt-h2>Contact Information</apt-h2>

        <div fxLayout="column" fxLayout.gt-sm="row" class="info-row" ngClass.lt-md="info-row-mobile">
          <div fxFlex fxLayout="column" fxLayoutAlign="start start">
            <ng-container *ngIf="!editingContact">
              <apt-text-label label="First Name">
                <apt-text-waiter
                  [fallbackTime]="60000"
                  style="word-break: break-all"
                  [text]="updateContactInfo.insuredContactFirstName"
                ></apt-text-waiter>
              </apt-text-label>
            </ng-container>
            <ng-container *ngIf="editingContact">
              <apt-form-row>
                <apt-input label="First Name" [aptInput]="fnameInput">
                  <input #fnameInput type="text" placeholder="First Name" formControlName="fname" required />
                </apt-input>
              </apt-form-row>
            </ng-container>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="start start">
            <ng-container *ngIf="!editingContact">
              <apt-text-label label="Last Name">
                <apt-text-waiter
                  [fallbackTime]="60000"
                  style="word-break: break-all"
                  [text]="updateContactInfo.insuredContactLastName"
                ></apt-text-waiter>
              </apt-text-label>
            </ng-container>

            <ng-container *ngIf="editingContact">
              <apt-form-row>
                <apt-input label="Last Name" [aptInput]="lnameInput">
                  <input #lnameInput type="text" placeholder="Last Name" formControlName="lname" required />
                </apt-input>
              </apt-form-row>
            </ng-container>
          </div>
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" class="info-row" ngClass.lt-md="info-row-mobile">
          <div fxFlex fxLayout="column" fxLayoutAlign="start start">
            <ng-container *ngIf="!editingContact">
              <apt-text-label label="Email Address">
                <apt-text-waiter
                  [fallbackTime]="60000"
                  style="word-break: break-all"
                  [text]="policyStore?.policyData?.policy?.insuredEmailTxt"
                ></apt-text-waiter>
              </apt-text-label>
            </ng-container>
            <ng-container *ngIf="editingContact">
              <apt-form-row>
                <apt-input label="Email Address" [aptInput]="emailInput">
                  <input #emailInput type="email" placeholder="Email Address" formControlName="email" required />
                </apt-input>
              </apt-form-row>
            </ng-container>
            <apt-inform [style.margin-top]="!editingContact ? '8px' : ''"
              >This email will be used to create your online account for managing your policy.</apt-inform
            >
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="start start">
            <ng-container *ngIf="!editingContact">
              <apt-text-label label="Phone Number">
                <apt-text-waiter
                  [fallbackTime]="60000"
                  style="word-break: break-all"
                  [text]="policyStore?.policyData?.policy?.insuredPhoneNo"
                ></apt-text-waiter>
              </apt-text-label>
            </ng-container>
            <ng-container *ngIf="editingContact">
              <apt-form-row>
                <apt-input label="Phone Number" [aptInput]="phoneInput" [errorMsg]="phoneMessages.error.pattern">
                  <input #phoneInput type="text" placeholder="Phone Number" formControlName="phone" required />
                </apt-input>
              </apt-form-row>
            </ng-container>
          </div>
        </div> -->

        <apt-h2>Policy Summary</apt-h2>

        <div fxLayout="column" fxLayout.gt-sm="row" class="info-row" ngClass.lt-md="info-row-mobile">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <ng-container *ngIf="!editingContact">
              <div fxLayout="row" fxLayoutAlign="center start">
                <apt-text-label label="Effective Date">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="policyStore?.policyData?.policy.policyEffectiveDate"
                  ></apt-text-waiter>
                </apt-text-label>
                <a class="editLink" (click)="doEditContactInfo()" *ngIf="!editingContact && !editingDisabled">
                  <fa-icon class="back-icon" [icon]="faEdit"></fa-icon> Edit</a
                >
                <!-- <dtc-button
                  class="editBtn"
                  [icon]="faEdit"
                  *ngIf="!editingContact && !editingDisabled"
                  secondary
                  (click)="doEditContactInfo()"
                >
                  Edit
                </dtc-button> -->
              </div>
            </ng-container>
            <ng-container *ngIf="editingContact">
              <apt-form-row>
                <apt-input label="Effective Date" [aptInput]="effDateInput">
                  <input
                    (change)="onChangeEffDate($event)"
                    (focus)="effDateInput.showPicker()"
                    #effDateInput
                    type="date"
                    placeholder="Effective Date"
                    formControlName="effDate"
                    required
                  />
                </apt-input>
              </apt-form-row>
            </ng-container>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Expiration Date">
              <apt-text-waiter
                [fallbackTime]="60000"
                [text]="policyStore?.policyData?.policy.policyExpirationDate"
              ></apt-text-waiter>
            </apt-text-label>
          </div>

          <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Retroactive Date"
              *ngIf="paramProduct != 'ea' && policyStore?.policyData?.policy.retroactiveDate">
              <apt-text-waiter [fallbackTime]="60000" [text]="policyStore?.policyData?.policy.retroactiveDate"></apt-text-waiter>
            </apt-text-label>
          </div> -->
        </div>

        <!-- <div fxLayout="column" fxLayout.gt-sm="row" class="info-row" ngClass.lt-md="info-row-mobile">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Named Insured">
              <apt-text-waiter [fallbackTime]="60000" [text]="policyStore?.policyData?.policy.addressDto.insuredName">
              </apt-text-waiter>
            </apt-text-label>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Mailing Address">
              <apt-text-waiter [fallbackTime]="60000" [text]="addressString"> </apt-text-waiter>
            </apt-text-label>
          </div>
        </div> -->

        <div fxLayout="column" fxLayout.gt-sm="row" class="info-row" ngClass.lt-md="info-row-mobile">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Product / Policy Type">
              <apt-text-waiter [fallbackTime]="60000" [text]="coverageLiteralName"> </apt-text-waiter>
            </apt-text-label>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Insurance Company">
              <apt-text-waiter
                [fallbackTime]="60000"
                [text]="
                  policyStore?.policyData?.policy.issuingCompany == 1
                    ? 'Arch Insurance Company'
                    : policyStore?.policyData?.policy.issuingCompany
                "
              >
              </apt-text-waiter>
            </apt-text-label>
          </div>
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" class="info-row" ngClass.lt-md="info-row-mobile">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Class of Business">
              <apt-text-waiter
                [fallbackTime]="60000"
                [text]="policyStore?.policyData?.referenceData.classOfBusiness.insuredClassofBusinessName"
              >
              </apt-text-waiter>
            </apt-text-label>
          </div>
        </div>

        <ng-container *ngIf="paramProduct != 'ea'">
          <div fxLayout="column" fxLayout.gt-sm="row" class="info-row" ngClass.lt-md="info-row-mobile">
            <div fxFlex fxLayout="column" fxLayoutAlign="center start">
              <apt-text-label label="Professional Liability Definition">
                <apt-text-waiter
                  [fallbackTime]="60000"
                  [text]="policyStore?.policyData?.referenceData.classOfBusiness.insuredClassofBusinessDesc"
                >
                  <!-- otherInsuredClassofBusinessDesc -->
                </apt-text-waiter>
              </apt-text-label>
            </div>
          </div>
        </ng-container>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="end center">
          <div>
            <ng-template *ngTemplateOutlet="contactButtons; context: { location: 'below' }"></ng-template>
          </div>
        </div>
      </form>

      <ng-template #limitsButtons let-location="location">
        <div
          fxLayout="row"
          class="edit-contact-info"
          *ngIf="policyStore?.policyData?.policy.quoteStatus === 'QTD' && paramProduct != 'ea'"
        >
          <dtc-button
            [icon]="faEdit"
            fxHide.lt-md
            *ngIf="!editingInfo && !editingDisabled && location == 'above'"
            secondary
            (click)="doEditInfo()"
          >
            Edit Limits/Retentions
          </dtc-button>

          <dtc-button
            [icon]="faEdit"
            fxHide.gt-sm
            *ngIf="!editingInfo && !editingDisabled && location == 'below'"
            secondary
            (click)="doEditInfo()"
          >
            Edit Limits/Retentions
          </dtc-button>

          <dtc-button
            *ngIf="editingInfo && !disableInfoCancel && location == 'below'"
            secondary
            (click)="cancelInfo()"
            [disabled]="savingInfo"
          >
            Cancel
          </dtc-button>
          <dtc-button
            *ngIf="editingInfo && location == 'below'"
            primary
            secondary
            type="submit"
            [disabled]="savingInfo || !infoForm.valid"
          >
            {{ savingInfo ? 'Saving...' : 'Save' }}
          </dtc-button>
        </div>
      </ng-template>

      <ng-container *ngIf="paramProduct != 'ea'">
        <form [formGroup]="infoForm" (ngSubmit)="submitInfoForm()" aptForm>
          <apt-h2 underline>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start center">
              <div fxFlex="70">Limits/Retentions</div>

              <div fxFlex fxLayout="row" fxHide.lt-md fxLayoutAlign.gt-sm="end center">
                <ng-template *ngTemplateOutlet="limitsButtons; context: { location: 'above' }"></ng-template>
              </div>
            </div>
          </apt-h2>
          <!-- <apt-h2>Limits/Retentions</apt-h2> -->

          <div fxLayout="column" fxLayout.gt-sm="row" class="info-row limits-box">
            <div fxFlex fxLayout="column" fxLayoutAlign="center start" ngClass.lt-md="mobile-limits">
              <ng-container *ngIf="!editingInfo">
                <apt-text-label label="Each Claim Limit">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="policyStore?.policyData?.policy.policyLimit | currency"
                  ></apt-text-waiter>
                </apt-text-label>
              </ng-container>
              <ng-container *ngIf="editingInfo">
                <apt-form-row>
                  <apt-select label="Each Claim Limit / Aggregate Limit" [aptSelect]="aptSelect">
                    <select #aptSelect formControlName="limit">
                      <option *ngFor="let limit of limitList" [value]="limit">{{ limit }}</option>
                    </select>
                  </apt-select>
                </apt-form-row>
              </ng-container>
            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="center start" ngClass.lt-md="mobile-limits">
              <ng-container *ngIf="!editingInfo">
                <apt-text-label label="Aggregate Limit">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="policyStore?.policyData?.policy.limitAggregate | currency"
                  ></apt-text-waiter>
                </apt-text-label>
              </ng-container>
            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="center start" ngClass.lt-md="mobile-limits">
              <ng-container *ngIf="!editingInfo">
                <apt-text-label label="Retention">
                  <apt-text-waiter
                    [fallbackTime]="60000"
                    [text]="
                      (policyStore?.policyData?.policy?.policyAggregateRetention
                        ? policyStore?.policyData?.policy?.policyAggregateRetention
                        : policyStore?.policyData?.policy?.quoteCoverageList[0]?.deductibleAmount
                      ) | currency
                    "
                  >
                  </apt-text-waiter>
                </apt-text-label>
              </ng-container>
              <ng-container *ngIf="editingInfo">
                <apt-form-row>
                  <apt-select label="Retention" [aptSelect]="aptRSelect">
                    <select #aptRSelect formControlName="retention">
                      <option *ngFor="let retention of retentionList" [value]="retention">{{ retention }}</option>
                    </select>
                  </apt-select>
                </apt-form-row>
              </ng-container>
            </div>
          </div>

          <div
            fxLayout="column"
            fxLayout.gt-sm="row"
            fxLayoutAlign="center center"
            fxLayoutAlign.gt-sm="end center"
            class="limits-button-mobile"
          >
            <div>
              <ng-template *ngTemplateOutlet="limitsButtons; context: { location: 'below' }"></ng-template>
            </div>
          </div>
        </form>
      </ng-container>

      <hr />

      <div style="text-align: right">
        <apt-inform state="error" *ngIf="mustGoBackToReferrer"
          >Payment/Bind cannot be processed for a quote with an effective date in the past. Please
          <span class="clickable" (click)="goBackToReferrer()">go back</span> to change the effective date.
        </apt-inform>
      </div>

      <div fxLayout="row" fxHide.lt-md fxLayoutAlign="end center">
        <ng-template *ngTemplateOutlet="paymentButton"></ng-template>
      </div>
    </ng-container>
    <ng-container right>
      <payment-summary
        #paymentSummary
        [refreshInstallments]="true"
        [prompt]="promptPaymentSchedule"
        [editable]="true"
        [policyStore]="policyStore"
        [product]="paramProduct"
        [consumer]="true"
      ></payment-summary>

      <div fxLayout="column" fxLayoutAlign="end center" fxHide.gt-sm class="mobile-pay-button">
        <ng-template *ngTemplateOutlet="paymentButton"></ng-template>
      </div>

      <contact-box [overrideTheme]="d2cTheme" [phoneType]="'phoneD2C'"></contact-box>

      <div style="margin-top: 16px" *ngIf="contactInfoError">
        <apt-inform state="error">{{ contactInfoError }}</apt-inform>
      </div>
      <div style="margin-top: 16px" *ngIf="infoError">
        <apt-inform state="error">{{ infoError }}</apt-inform>
      </div>
    </ng-container>
  </dtc-page-content>
</ng-container>

<apt-dialog title="" modal="true" [(open)]="isPreparingQuote" width="670px" [showCloseX]="false">
  <ng-container dialogBody>
    <apt-waiter
      *ngIf="agentCode == 18089"
      [evaluate]="policyStore?.policyData?.policy.quoteStatus === 'QTD'"
      [title]="preparingTitle"
      [rollingText]="preparingMessages"
      timeout="120000"
      [rollingWait]="5000"
      [repeat]="false"
      [(timedOut)]="prepareQuoteTimedOut"
      [contactInfo]="emptyContactMsg"
      [error]="preparingError"
      (timedOutChange)="prepareTimeOutChange($event)"
    >
    </apt-waiter>
    <apt-waiter
      *ngIf="agentCode != 18089"
      [evaluate]="policyStore?.policyData?.policy.quoteStatus === 'QTD'"
      [title]="preparingTitle"
      [rollingText]="preparingMessages"
      timeout="120000"
      [rollingWait]="5000"
      [repeat]="false"
      [(timedOut)]="prepareQuoteTimedOut"
      [error]="preparingError"
      (timedOutChange)="prepareTimeOutChange($event)"
    >
    </apt-waiter>
    <!-- <apt-waiter *ngIf=""
    [evaluate]="policyStore?.policyData?.policy.quoteStatus === 'QTD'"
    [title]="preparingTitle"
    [rollingText]="preparingMessages"
    timeout="120000"
    [rollingWait]="5000"
    [repeat]="false"
    [(timedOut)]="prepareQuoteTimedOut"
    [error]="preparingError"
    [contactInfo]="hello"
    (timedOutChange)="prepareTimeOutChange($event)"
  >
  </apt-waiter> -->

    <p class="in-progress-refresh-message">
      This page will automatically refresh. Or you can
      <span class="clickable" (click)="refreshPage()">click here to refresh now</span>.
    </p>
  </ng-container>
</apt-dialog>

<apt-dialog [title]="pageLoadErrorTitle" modal="true" [(open)]="showErrorDialog" [showCloseX]="false">
  <ng-container dialogBody>
    <p>{{ pageLoadErrorMessage }}</p>
  </ng-container>
</apt-dialog>

<apt-dialog
  title=""
  modal="true"
  [(open)]="savingContactInfo || savingInfo"
  width="670px"
  [showCloseX]="contactInfoError || infoError"
>
  <ng-container dialogBody>
    <apt-waiter
      *ngIf="agentCode != 18089"
      [evaluate]="false"
      title="Updating"
      [rollingText]="updatingMessages"
      timeout="0"
      [rollingWait]="5000"
      [repeat]="true"
      [error]="contactInfoError || infoError"
    >
    </apt-waiter>
    <apt-waiter
      *ngIf="agentCode == 18089"
      [evaluate]="false"
      title="Updating"
      [rollingText]="updatingMessages"
      timeout="0"
      [rollingWait]="5000"
      [repeat]="true"
      [contactInfo]="emptyContactMsg"
      [error]="contactInfoError || infoError"
    >
    </apt-waiter>
  </ng-container>
</apt-dialog>

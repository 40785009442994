import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyData } from '@app/interfaces/policy.interface';
import { BillingInfoService } from '@shared/services/billing-info.service';
import { BillingStore } from '@app/interfaces/billing-store.interface';
import { BillingStoreService } from '@app/home/store/billing-store.service';
import { AbstractControl, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';
// import { environment } from '@env/environment';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';
import { faEdit, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { PaymentSummaryComponent } from '@shared/components/payment-summary/payment-summary.component';
import { constants } from '@app/@shared/constants';
import { AptFeatureFlagService } from '@archinsurance-da/arch-portal-tools';
import { DatePipe } from '@angular/common';

const DEFAULT_PRODUCT = 'essentialpl';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent implements OnInit {
  policyStore: BillingStore = {};
  agentCode: any = '18089';
  USETESTDATA: boolean = false;
  contactUpdateStatus: boolean = false;
  infoUpdateStatus: boolean = false;
  BYPASSBINDCALL: boolean = true; //set to true for pay first bind after, false will bind before going to payment
  addressString: string = undefined;
  bindDialog: boolean = false;
  policyRequested: boolean = false;
  paramPolicyId: string;
  editingContact: boolean = false;
  updateContactInfo: any = {
    // insuredContactFirstName: '',
    // insuredContactLastName: '',
    // insuredEmailTxt: '',
    // insuredPhoneNo: '',
    effectiveDate: '',
  };
  updateInfo: any = {
    limit: '',
    limitAggregate: '',
    retention: '',
  };
  coverageLiteralName: string;
  downloading: boolean = false;
  paramProduct: string = DEFAULT_PRODUCT;
  faEdit: IconDefinition = faEdit;
  editingInfo: boolean = false;
  theme: Theme;
  limitList: any[] = [];
  retentionList: any[] = [];
  limitAndRetentionFetched = false;
  @ViewChild('paymentSummary') paymentSummary: PaymentSummaryComponent;

  TESTING_IPG: boolean = false;

  mustGoBackToReferrer: boolean = false;
  goBackToReferrer() {
    history.back();
  }

  flags: any;

  //quote status, if bound send to payment, if issued show info screen

  overridePastEffDateByGUIDs: string[] = [];
  policyIsPastExempt: boolean;
  editingDisabled: boolean = false;

  datePipe = new DatePipe('en-US');

  constructor(
    private fb: UntypedFormBuilder,
    private billingInfoService: BillingInfoService,
    private billingStoreService: BillingStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private themeService: ThemingService,
    private ffService: AptFeatureFlagService
  ) {}

  get userProfile(): UserProfile {
    return this.authenticationService.userProfile;
  }

  setoOverridePastEffDateByGUIDsRule() {
    // console.log('this.overridePastEffDateByGUIDs', this.overridePastEffDateByGUIDs);
    const routeParams = this.route.snapshot.paramMap;
    const policyId = routeParams.get('id');
    const policyinList: boolean = this.overridePastEffDateByGUIDs && this.overridePastEffDateByGUIDs.includes(policyId);
    this.policyIsPastExempt = policyinList;
    this.editingDisabled = policyinList;
  }

  phoneMessages = {
    error: {
      pattern: 'Phone number must only be numbers, dashes, and periods.',
    },
  };

  contactInfoForm = this.fb.group({
    // fname: ['', Validators.required],
    // lname: ['', Validators.required],
    // email: ['', [Validators.required, Validators.email]],
    // phone: ['', [Validators.required, Validators.pattern('^[0-9.-]*$')]],
    effDate: ['', [Validators.required, this.effDateValidator()]],
  });
  infoForm = this.fb.group({
    limit: ['', Validators.required],
    retention: ['', [Validators.required]],
  });
  effDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let dateVal = this.datePipe.transform(control.value, 'MM/dd/yyyy');

      const validDate = this.isValidDate(dateVal);
      const currentDate = this.isCurrentDate(dateVal);

      if (validDate && (currentDate || this.policyIsPastExempt)) {
        //also check format
        return null;
      } else {
        let ret = {};

        if (!currentDate) {
          ret['pastDate'] = {
            value: dateVal,
            message: 'Cannot be in the past or more than 60 days in the future',
          };
        }

        if (!validDate) {
          ret['invalidDate'] = {
            value: dateVal,
            message: 'Invalid date. Must be MM/dd/YYYY',
          };
        }

        return ret;
      }
    };
  }

  isValidDate(s: string) {
    // Assumes s is "mm/dd/yyyy"
    if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(s)) {
      return false;
    }
    const parts = s.split('/').map((p) => parseInt(p, 10));
    parts[0] -= 1;
    const d = new Date(parts[2], parts[0], parts[1]);
    return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
  }

  isCurrentDate(dateString: string) {
    const validDate = this.isValidDate(dateString);
    let isCurrentDate = false;
    if (validDate) {
      let dateObj = new Date(dateString);
      dateObj.setHours(0, 0, 0, 0);
      let nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);
      let sixtyDate = new Date();
      sixtyDate.setDate(sixtyDate.getDate() + 60);
      sixtyDate.setHours(0, 0, 0, 0);

      // console.log('dateObj', dateObj);
      // console.log('sixtyDate', sixtyDate);

      isCurrentDate = dateObj >= nowDate && dateObj <= sixtyDate;
    }
    return isCurrentDate;
  }

  contactInfoError: string = '';
  savingContactInfo: boolean = false;
  lastContactInfo: any = {};
  disableContactInfoCancel: boolean = false;
  infoError: string = '';
  savingInfo: boolean = false;
  lastInfo: any = {};
  disableInfoCancel: boolean = false;

  doEditContactInfo() {
    if (this.policyStore?.policyData?.policy.quoteStatus === 'QTD') {
      this.lastContactInfo = JSON.parse(JSON.stringify(this.updateContactInfo)); //copy object
      this.editingContact = true;
    } else {
      this.editingContact = false;
    }
  }

  doEditInfo() {
    if (this.policyStore?.policyData?.policy.quoteStatus === 'QTD') {
      this.lastInfo = JSON.parse(JSON.stringify(this.updateInfo)); //copy object
      this.editingInfo = true;
    } else {
      this.editingInfo = false;
    }
  }

  updatingMessages: any[] = [
    'Your time is very important to us.',
    'Your quote is being updated.',
    'Your quote will be ready shortly. Thank you for your patience.',
  ];

  submitContactForm(): void {
    if (this.contactUpdateStatus == true) {
      this.savingContactInfo = true;
      this.contactInfoError = '';
      this.disableContactInfoCancel = false;

      // this.policyStore.policyData.policy.insuredContactPerson =
      //   this.contactInfoForm.value.fname + ' ' + this.contactInfoForm.value.lname;
      // this.policyStore.policyData.policy.insuredEmailTxt = this.contactInfoForm.value.email;
      // this.policyStore.policyData.policy.insuredPhoneNo = this.contactInfoForm.value.phone;

      let effectiveDate = this.datePipe.transform(this.contactInfoForm.value.effDate, 'MM/dd/yyyy');
      this.policyStore.policyData.policy.policyEffectiveDate = effectiveDate;

      let expDateObj = new Date(effectiveDate);
      expDateObj.setFullYear(expDateObj.getFullYear() + 1);
      // this.policyStore.policyData.policy.policyExpirationDate = `${
      //   expDateObj.getMonth() + 1
      // }/${expDateObj.getDate()}/${expDateObj.getFullYear()}`;
      this.policyStore.policyData.policy.policyExpirationDate = expDateObj.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });

      this.policyStore.policyData.policy.retroactiveDate = effectiveDate;
      this.policyStore.policyData.policy.transactionEffectiveDate = effectiveDate;

      //update original response json
      // this.policyStore.policyData.responseJSON.policy.Insured_Contact_Person =
      //   this.policyStore.policyData.policy.insuredContactPerson;
      // this.policyStore.policyData.responseJSON.policy.Insured_Email_Txt =
      //   this.policyStore.policyData.policy.insuredEmailTxt;
      // this.policyStore.policyData.responseJSON.policy.Insured_Phone_No =
      //   this.policyStore.policyData.policy.insuredPhoneNo;
      //Dates
      this.policyStore.policyData.responseJSON.policy.policyEffDt =
        this.policyStore.policyData.policy.transactionEffectiveDate;
      this.policyStore.policyData.responseJSON.policy.policyExpDt =
        this.policyStore.policyData.policy.policyExpirationDate;
      this.policyStore.policyData.responseJSON.policy.retroactiveDate =
        this.policyStore.policyData.policy.retroactiveDate;

      this.billingStoreService.setBillingStore(this.policyStore);

      this.billingInfoService.updatePolicy(this.policyStore.policyData, true).subscribe((data) => {
        // this.editingContact = false;
        if (data && data.returnCode == 'SUCCESS') {
          this.savingContactInfo = false;
          let policyEffDate = new Date(this.policyStore.policyData.policy.policyEffectiveDate);
          let policyExpDate = new Date(this.policyStore.policyData.policy.policyExpirationDate);
          let policyRetroDate = new Date(this.policyStore.policyData.policy.retroactiveDate);
          this.policyStore.effectiveDate = policyEffDate.toISOString().split('T').shift();
          this.policyStore.expirationDate = policyExpDate.toISOString().split('T').shift();
          this.policyStore.retroactiveDate = policyRetroDate.toISOString().split('T').shift();

          // Update contact info for non edit view
          this.updateContactInfo = {
            // insuredContactFirstName: this.contactInfoForm.value.fname,
            // insuredContactLastName: this.contactInfoForm.value.lname,
            // insuredEmailTxt: this.contactInfoForm.value.email,
            // insuredPhoneNo: this.contactInfoForm.value.phone,
            effectiveDate: this.contactInfoForm.value.effDate,
          };
          this.editingContact = false;

          location.reload();
          //RELOAD PAYMENT SCHEDULE, reloaded by child component
          //this.paymentSummary.getPaymentSchedule();
        } else {
          console.error('Failed to update', data);
          this.contactInfoError = 'Unable to update contact information. Please contact us for support.';
        }
      });
    } else {
      this.editingContact = false;
    }
  }

  submitInfoForm(): void {
    let limit = this.convertInfoToval();
    const replaceDollarComma = /\$|\,/g;
    if (
      this.policyStore?.policyData?.policy.policyLimit != limit[0].replace(replaceDollarComma, '').trim() ||
      this.policyStore.policyData.policy.limitAggregate != limit[1].replace(replaceDollarComma, '').trim() ||
      this.policyStore?.policyData?.policy.policyAggregateRetention !=
        this.inverseCommaToString(this.infoForm.value.retention).replace(replaceDollarComma, '').trim()
    ) {
      this.infoUpdateStatus = true;
    }

    if (this.infoUpdateStatus == true) {
      this.savingInfo = true;
      this.infoError = '';
      this.disableInfoCancel = false;

      this.policyStore.policyData.policy.quoteCoverageList = [
        {
          coverageId: '',
          aprPremium: '',
          itdPremium: '',
          extAction: 'U',
          action: null,
          coverageCodeInfo: {
            coverageLiteralName: this.coverageLiteralName,
            coverageCodeName: 'SPL',
          },
          limitAmt: limit[0].replace(replaceDollarComma, '').trim(),
          aggregateAmt: limit[1].replace(replaceDollarComma, '').trim(),
          deductibleAmount: this.inverseCommaToString(this.infoForm.value.retention)
            .replace(replaceDollarComma, '')
            .trim(),
          occurenceLimit: null,
          coverageTriggerCode: null,
          aggregateLimit: null,
          appCodeLimit: null,
          deductibleTypeCode: null,
          deductibleAppCode: null,
          asLob: null,
          sublineCode: null,
          limitTypeCode: null,

          aggregateDeductibleAmount: null,

          sumlimit1Amt: null,
          sumlimit2Amt: null,
          thirdPartyDeductible: null,
          litigationDateThirdParty: null,

          basePremium: null,

          aggregateRetention: null,
          deductible3Amount: null,
          coverageEffectiveDt: null,
          coverageExpirationDt: null,
          litigationDate: null,
          //"coveragecommissionAmt": "303.4000",
          annualPremium: null,
          prorateFactor: null,
          proratedPremium: null,
          prevCovExpDt: null,
          litigationDateDesc: null,
          itdCovCommissionAmt: null,
          cyberCLimit: null,
          retroactiveDate: null,
          retroactiveDateDesc: null,
          waitingPeriod: null,
          fpaInd: null,
          subLimitList: null,
          partOf1Amt: null,
          enteredPremium: null,
        },
      ];
      this.billingStoreService.setBillingStore(this.policyStore);

      // console.log('this.policyStore.policyData.policy.quoteCoverageList', this.policyStore.policyData.policy.quoteCoverageList);
      this.billingInfoService.updatePolicy(this.policyStore.policyData, true).subscribe((data) => {
        // this.editingContact = false;
        if (data && data.returnCode == 'SUCCESS') {
          this.savingInfo = false;
          let policyEffDate = new Date(this.policyStore.policyData.policy.policyEffectiveDate);
          let policyExpDate = new Date(this.policyStore.policyData.policy.policyExpirationDate);
          let policyRetroDate = new Date(this.policyStore.policyData.policy.retroactiveDate);
          this.policyStore.effectiveDate = policyEffDate.toISOString().split('T').shift();
          this.policyStore.expirationDate = policyExpDate.toISOString().split('T').shift();
          this.policyStore.retroactiveDate = policyRetroDate.toISOString().split('T').shift();
          this.policyStore.policyData.policy.policyAggregateRetention = this.inverseCommaToString(
            this.infoForm.value.retention
          );

          let limit = this.convertInfoToval();

          this.policyStore.policyData.policy.policyLimit = limit[0];
          this.policyStore.policyData.policy.limitAggregate = limit[1];
          // Update contact info for non edit view

          this.updateInfo = {
            limit: limit[0],
            limitAggregate: limit[1],
            retention: this.inverseCommaToString(this.infoForm.value.retention),
          };
          this.editingInfo = false;

          //RELOAD PAYMENT SCHEDULE
          // this.getPaymentSchedule();//handled by shared component now

          location.reload();
        } else {
          console.error('Failed to update', data);
          this.infoError = 'Unable to update  information. Please contact us for support.';
        }
      });
    } else {
      this.editingInfo = false;
    }
  }
  convertInfoToval() {
    let limitVal = this.infoForm.value.limit.split('/');
    let limit = this.inverseCommaToString(this.removeCurrencySign(limitVal[0]));
    let limitAggregate = this.inverseCommaToString(this.removeCurrencySign(limitVal[1]));
    return [limit, limitAggregate];
  }

  cancelContactInfo() {
    this.editingContact = false;

    //reset info
    this.updateContactInfo = JSON.parse(JSON.stringify(this.lastContactInfo)); //copy object

    this.effdateChange(this.updateContactInfo.effectiveDate);

    //also reset the form field values
    this.contactInfoForm.patchValue({
      // fname: this.updateContactInfo.insuredContactFirstName,
      // lname: this.updateContactInfo.insuredContactLastName,
      // email: this.updateContactInfo.insuredEmailTxt,
      // phone: this.updateContactInfo.insuredPhoneNo,
      effDate: this.datePipe.transform(this.updateContactInfo.effectiveDate, 'yyyy-MM-dd'),
    });
  }
  cancelInfo() {
    this.editingInfo = false;

    //reset info
    this.updateInfo = JSON.parse(JSON.stringify(this.lastInfo)); //copy object
    //also reset the form field values
    this.infoForm.patchValue({
      effDate: this.updateInfo.effectiveDate,
      limit:
        '$' +
        this.convertStringToComma(this.updateInfo.limit) +
        ' / ' +
        '$' +
        this.convertStringToComma(this.updateInfo.limitAggregate),
      retention: this.convertStringToComma(this.updateInfo.retention),
    });
  }

  producer: string = ''; //default nationwide
  setProducer(overrideProducerId?: string) {
    if (
      overrideProducerId &&
      !/^P/.test(overrideProducerId) &&
      overrideProducerId !== 'generic' &&
      overrideProducerId !== 'auto'
    ) {
      overrideProducerId = `P${overrideProducerId}`;
    }

    if (overrideProducerId && overrideProducerId != 'default') {
      this.producer = overrideProducerId;
    } else {
      this.producer = ''; //UNSET to fall back on generic
    }

    if (this.paramProduct == 'ea') {
      this.producer = 'P43580'; //Canopy
    }

    // console.log('this.producer', this.producer);

    if (constants.producerIds.hasOwnProperty(this.producer) && constants.producerIds[this.producer].theme) {
      // console.log('constants.producerIds[this.producer]', constants.producerIds[this.producer]);
      this.themeService.setTheme(constants.producerIds[this.producer].theme);
    } else if (!this.producer) {
      this.themeService.setTheme('generic');
    }
  }

  d2cTheme: Theme;
  pageLoadErrorMessage: string = '';
  pageLoadErrorTitle: string = '';
  showErrorDialog: boolean = false;

  ngOnInit() {
    // const currentMaintenances = this.maintenanceService.isScheduledMaintenance();
    // const currentMaint = currentMaintenances[0];
    // if (currentMaint) {
    //   this.router.navigate(['maintenance']);
    // }
    this.ffService.flagChange.subscribe((flags: any) => {
      const overridePastEffDateByGUIDs = !flags.overridePastEffDateByGUIDs?.current
        ? flags.overridePastEffDateByGUIDs
        : flags.overridePastEffDateByGUIDs.current;
      this.overridePastEffDateByGUIDs = overridePastEffDateByGUIDs;
      this.setoOverridePastEffDateByGUIDsRule();

      const overrideProducerId =
        typeof flags.overrideProducerId != 'string' ? flags.overrideProducerId.current : flags.overrideProducerId;
      if (this.producer != overrideProducerId) {
        this.setProducer(overrideProducerId);
      }
    });

    this.themeService.themeChange.subscribe((theme: Theme) => {
      this.theme = theme;

      this.d2cTheme = JSON.parse(JSON.stringify(this.theme));
      this.d2cTheme.agent.phone = this.theme.agent.phoneD2C;
    });

    //TODO: move this into getPolicy once we can get the producer from the payload.
    // this.setProducer();

    if (this.USETESTDATA) {
      //START TEST DATA:( for testing, should come from a real Policy)
      let policyEffDate = new Date();
      let policyExpDate = new Date();
      policyExpDate.setFullYear(policyEffDate.getFullYear() + 1);
      const price: number = 2000;

      this.policyStore = {
        total: price,
        dueToday: price,
        effectiveDate: policyEffDate.toISOString().split('T').shift(),
        expirationDate: policyExpDate.toISOString().split('T').shift(),
        paymentProposal: '1PAY',
      };
      //END TEST DATA
      // this.updatePaymentProposal();
    } else {
      //get from api and save in the billing store
      const routeParams = this.route.snapshot.paramMap;
      const policyId = routeParams.get('id');
      this.paramPolicyId = policyId;
      const product = routeParams.get('product');

      this.paramProduct = product == 'ea' ? product : DEFAULT_PRODUCT;
      if (this.paramPolicyId == 'unknown' || !this.paramPolicyId) {
        this.policyRequested = true;
      } else {
        this.billingInfoService
          .getPolicy(atob(policyId), this.paramProduct, true)
          .subscribe((policyData: PolicyData) => {
            const agentCode = policyData.policy.agentCd;
            this.agentCode = agentCode;
          });
        setTimeout(() => {
          this.setIPGPreparing('Loading Quote'); //start the loader so that they see somethign while getPolicy is running

          this.billingInfoService
            .getPolicy(atob(policyId), this.paramProduct, true)
            .subscribe((policyData: PolicyData) => {
              this.policyRequested = true;
              this.getLimitandRetentions(policyData);
              // console.log('policyData.policy.agentCd', policyData.policy.agentCd);
              const agentCode = policyData.policy.agentCd;
              this.agentCode = agentCode;
              // console.log('agentCode', agentCode);
              // console.log('policyData.policy', policyData.policy);
              // console.log('policyData.policy string', JSON.stringify(policyData.policy));

              this.setProducer(agentCode);

              let policyEffDate = new Date(policyData.policy.policyEffectiveDate);
              let policyExpDate = new Date(policyData.policy.policyExpirationDate);
              let policyRetroDate = policyData.policy.retroactiveDate
                ? new Date(policyData.policy.retroactiveDate)
                : undefined;

              let tmpPolicyStore: BillingStore = this.billingStoreService.getBillingStore();

              let paymentProposal = policyData?.policy?.paymentPlanCd ? policyData.policy.paymentPlanCd : ''; //no default

              if (tmpPolicyStore && tmpPolicyStore?.policyData && tmpPolicyStore?.id == this.paramPolicyId) {
                paymentProposal = tmpPolicyStore.paymentProposal; //remember previoulsy chosen paymentProposal
              }

              // console.log('policyData?.policy?.guid', policyData?.policy?.guid);

              this.policyStore = {
                id: policyData?.policy?.guid || policyData?.policy?.policyId,
                total: parseFloat(policyData.policy.policyITDPremium),
                dueToday: parseFloat(policyData.policy.policyITDPremium),
                effectiveDate: policyEffDate.toISOString().split('T').shift(),
                expirationDate: policyExpDate.toISOString().split('T').shift(),
                retroactiveDate: policyRetroDate ? policyRetroDate.toISOString().split('T').shift() : '',
                paymentProposal: paymentProposal ? paymentProposal : '', //no default
                policyData: policyData,
                product: this.paramProduct,
              };

              if (this.TESTING_IPG) {
                this.policyStore.policyData.policy.quoteStatus = 'IPG';
              }

              if (this.policyStore?.policyData?.policy.quoteStatus !== 'QTD') {
                let refreshCnt: number = 0;
                refreshCnt = sessionStorage.getItem('refreshCount_' + policyId)
                  ? Number(localStorage.getItem('refreshCount_' + policyId)) + 1
                  : 0;
                if (refreshCnt > 9) {
                  sessionStorage.setItem('refreshCount_' + policyId, '0');
                  this.stopIPGPreparing();
                  this.showErrorDialog = true;
                  this.pageLoadErrorTitle = 'Error Loading Information';
                  this.pageLoadErrorMessage =
                    'Unable to load policy. Please try again or contact us for support at ' +
                    this.d2cTheme.agent.phone +
                    ' or email us at customerservice@archinsurance.com';
                } else {
                  sessionStorage.setItem('refreshCount_' + policyId, refreshCnt.toString());
                  this.setIPGPreparing();
                }
              } else {
                sessionStorage.setItem('refreshCount_' + policyId, '0');
                this.stopIPGPreparing();
              }

              //const addressDto = policyData?.policy.addressDto;
              // this.addressString = `${addressDto.insuredAddressStreetLine1Txt}, ${addressDto.insuredAddressCityTxt}, ${addressDto.insuredAddressStateCd} ${addressDto.insuredAddressPostalCd}`;

              // console.log('queryParamMap', this.route.snapshot.queryParamMap.get('redirectUrl'));
              this.policyStore.redirectUrl = this.getRedirectUrl(); //Set redirectUrl into store to be used after successful payment.

              // console.log('this.policyStore.redirectUrl', this.policyStore.redirectUrl, atob(this.policyStore.redirectUrl));
              // console.table(this.policyStore);

              this.billingStoreService.setBillingStore(this.policyStore);

              // this.updatePaymentProposal();

              // let nameParts = this.policyStore?.policyData?.policy?.insuredContactPerson.split(' ');

              // this.updateContactInfo.insuredContactFirstName = nameParts.splice(0, 1)[0]; //remove and return first word
              // this.updateContactInfo.insuredContactLastName = nameParts.join(' '); //what's left is the rest of the name
              // this.updateContactInfo.insuredEmailTxt = this.policyStore?.policyData?.policy?.insuredEmailTxt;
              // this.updateContactInfo.insuredPhoneNo = this.policyStore?.policyData?.policy?.insuredPhoneNo;
              // // console.log('this.policyStore.policyData.policy', this.policyStore?.policyData?.policy);
              this.updateContactInfo.effectiveDate = this.policyStore?.policyData?.policy?.policyEffectiveDate;
              this.updateInfo.limit = this.policyStore?.policyData?.policy?.policyLimit;
              this.updateInfo.limitAggregate = this.policyStore?.policyData?.policy?.limitAggregate;
              // this.updateInfo.retention = this.policyStore?.policyData?.policy?.policyAggregateRetention
              //   ? this.policyStore?.policyData?.policy?.policyAggregateRetention
              //   : this.policyStore?.policyData?.policy?.quoteCoverageList
              //   ? this.policyStore?.policyData?.policy?.quoteCoverageList[0]?.deductibleAmount
              //   : 0;
              this.updateInfo.retention = this.policyStore?.policyData?.policy?.policyAggregateRetention
                ? this.policyStore?.policyData?.policy?.policyAggregateRetention
                : this.policyStore?.policyData?.policy?.quoteCoverageList[0]?.deductibleAmount;

              this.contactInfoForm.setValue({
                // fname: this.updateContactInfo.insuredContactFirstName,
                // lname: this.updateContactInfo.insuredContactLastName,
                // email: this.updateContactInfo.insuredEmailTxt,
                // phone: this.updateContactInfo.insuredPhoneNo,
                effDate: this.datePipe.transform(this.updateContactInfo.effectiveDate, 'yyyy-MM-dd'),
              });

              this.infoForm.setValue({
                limit:
                  '$' +
                  this.convertStringToComma(this.updateInfo.limit) +
                  ' / ' +
                  '$' +
                  this.convertStringToComma(this.updateInfo.limitAggregate),
                retention: this.convertStringToComma(this.updateInfo.retention),
              });

              const validEffDate = this.isValidDate(this.updateContactInfo.effectiveDate);
              const isCurrentEffDate = this.isCurrentDate(this.updateContactInfo.effectiveDate);

              if (!validEffDate || (!isCurrentEffDate && !this.policyIsPastExempt)) {
                this.disableContactInfoCancel = true;

                if (this.paramProduct !== 'ea') {
                  this.doEditContactInfo();

                  setTimeout(() => {
                    //must have last name
                    // if (!this.updateContactInfo.insuredContactLastName) {
                    //   let lnameFControl = this.contactInfoForm.get('lname');
                    //   lnameFControl.markAsTouched();
                    //   lnameFControl.markAsDirty();
                    //   lnameFControl.updateValueAndValidity();
                    // }

                    //must have first name
                    // if (!this.updateContactInfo.insuredContactFirstName) {
                    //   let fnameFControl = this.contactInfoForm.get('fname');
                    //   fnameFControl.markAsTouched();
                    //   fnameFControl.markAsDirty();
                    //   fnameFControl.updateValueAndValidity();
                    // }

                    if (!validEffDate || !isCurrentEffDate) {
                      let effDateFControl = this.contactInfoForm.get('effDate');
                      effDateFControl.markAsTouched();
                      effDateFControl.markAsDirty();
                      effDateFControl.updateValueAndValidity();
                    }
                  }, 100);
                } else {
                  this.mustGoBackToReferrer = true;
                }
              }

              if (this.policyStore?.policyData?.policy?.quoteCoverageList) {
                this.coverageLiteralName =
                  this.policyStore?.policyData?.policy?.quoteCoverageList[0].coverageCodeInfo.coverageLiteralName.replace(
                    'Miscellaneous/Specified',
                    'Miscellaneous'
                  );
              }
              // console.log(
              //   'this.policyStore?.policyData?.referenceData?.product?.productOffering',
              //   this.policyStore?.policyData?.referenceData?.product?.productOffering
              // );

              if (this.policyStore?.policyData?.referenceData?.product?.productOffering) {
                const productMapping = {
                  PRDCNP: 'Management Liability', //'Arch Corporate Canopy Policy Private Company Management Liability & Crime Insurance',
                  MSPPL: 'Miscellaneous/Specified Professional Liability',
                  MSSPL: 'Miscellaneous/Specified Professional Liability',
                };
                let productOffering = this.policyStore.policyData.referenceData.product.productOffering;
                // console.log('productOffering', productOffering);
                this.coverageLiteralName = productMapping[productOffering]
                  ? productMapping[productOffering]
                  : productOffering;
              }

              this.billingStoreService.setBillingStore(this.policyStore);
            });
        });
      }
    }
  }

  getRedirectUrl() {
    let ru = this.route.snapshot.queryParamMap.get('redirectUrl');
    return ru ? ru : this.theme.redirectUrl;
  }

  bindForm = this.fb.group({
    affirm: ['', Validators.required],
    autopay: ['', Validators.required],
    legal: ['', Validators.required],
    legal2: ['', Validators.required],
  });
  convertStringToComma(number: any) {
    return number >= 0
      ? number
          ?.toString()
          .trim()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      : number;
  }
  inverseCommaToString(number: any) {
    return number >= 0 ? number?.toString().trim().replace(/,/g, '') : number;
  }
  removeCurrencySign(number: any) {
    return number >= 0 ? number?.toString().trim().replace(/\$/g, '') : number;
  }

  getLimitandRetentions(policyData: PolicyData) {
    let agentCd = policyData?.responseJSON?.policy?.agentCd;
    let productOffering = policyData?.responseJSON?.policy?.productOffering;
    let product = this.paramProduct == DEFAULT_PRODUCT ? 'PL' : this.paramProduct;

    // console.log(productOffering);
    // console.log(product);
    this.billingInfoService.getLimitandRetention(agentCd, product, productOffering, true).subscribe(
      (data: any) => {
        this.limitAndRetentionFetched = true;
        if (data && data[0]) {
          this.limitList = data[0]['policyLmt_aggLmt'];
          this.retentionList = data[0]['retentions'];
        } else {
          this.limitList = [];
          this.retentionList = [];
        }
      },
      (error: any) => {
        this.limitList = [];
        this.retentionList = [];
      }
    );
  }
  setBindOnQuote(policyData: any) {
    this.policyStore.policyData = policyData;
    console.info(`%c Policy Number ${this.policyStore.policyData.policy.polNbr}`, 'font-weight: bold; color: #0057b8');
    this.billingStoreService.setBillingStore(this.policyStore);
  }

  onChangeEffDate(event: any) {
    if (event.target.value) {
      this.contactUpdateStatus = true;
      this.effdateChange(event.target.value);
    }
  }

  effdateChange(effDate: string) {
    let effectiveDate = this.datePipe.transform(effDate, 'MM/dd/yyyy');

    this.policyStore.policyData.policy.policyEffectiveDate = effectiveDate;

    let expDateObj = new Date(effectiveDate);
    expDateObj.setFullYear(expDateObj.getFullYear() + 1);
    // this.policyStore.policyData.policy.policyExpirationDate = `${
    //   expDateObj.getMonth() + 1
    // }/${expDateObj.getDate()}/${expDateObj.getFullYear()}`;
    this.policyStore.policyData.policy.policyExpirationDate = expDateObj.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    this.policyStore.policyData.policy.retroactiveDate = effectiveDate;
  }

  updateProducer() {
    if (this.policyStore.policyData.referenceData.branch.agencyUnderwriterEmail != this.userProfile.email) {
      this.policyStore.policyData.referenceData.branch.agencyUnderwriterEmail = this.userProfile.email;
      this.policyStore.policyData.referenceData.branch.agencyUnderwriterName = `${this.userProfile.given_name} ${this.userProfile.family_name}`;
      this.policyStore.policyData.referenceData.branch.agentAssignedUW = this.userProfile.email;
      this.policyStore.policyData.responseJSON.policy.underwriterEmailAddress = this.userProfile.email;

      this.billingStoreService.setBillingStore(this.policyStore);

      this.billingInfoService.updatePolicy(this.policyStore.policyData, true).subscribe((data) => {
        if (data && data.returnCode == 'SUCCESS') {
          //nothing
        } else {
          //nothing yet
        }
      });
    }
  }

  openBindDialog(): void {
    this.bindDialog = true;
    // this.updateProducer();//sending this in on postProcessing only now.
  }

  promptPaymentSchedule: boolean = false;

  goToConfirm() {
    if (this.policyStore.paymentProposal) {
      this.router.navigate(['direct/confirm']);
    } else {
      this.promptPaymentSchedule = true;
      setTimeout(() => {
        this.promptPaymentSchedule = false;
      }, 1500);
    }
  }

  bindingMessages: string[] = [
    'Your request is being processed.',
    'Saving updated information to your policy.',
    'Updating and synchronizing our records.',
    'Binding your policy to be ready to issue.',
    'Polishing up final details.',
    'This should be completed soon.',
    'Your time is very important to us.',
    'Your request is still being processed, please wait.',
    'We should be finishing up shortly. Thank you for your patience.',
  ];
  doingBind: boolean = false;
  emptyContactMsg: string = '';
  bindFinished: boolean = true;
  bindTimedOut: boolean = false;
  bindError: string;

  doBind(): void {
    this.bindFinished = false;
    this.bindDialog = false;
    this.doingBind = true;
    this.bindError = '';

    // this.BYPASSBINDCALL = true;//uncomment for testing

    // call bind then refirect to payment
    if (!this.BYPASSBINDCALL) {
      this.billingInfoService.bindPolicy(this.policyStore).subscribe(
        (data) => {
          // console.log('bind response data', data);
          if (data && data.status == 'BOUND' && data?.detail?.policyNumber) {
            this.goToConfirm();
          } else {
            this.doingBind = false;
            this.bindTimedOut = true;
            this.bindError = 'There was a problem binding this policy. Please contact our support for help.';
            console.error('Failed to Bind', data);
            //TODO some error handling here
            // this.router.navigate(['payer-info']);
          }
        },
        (data) => {
          this.bindError = 'There was a problem binding this policy. Please contact our support for help.';
          console.error('Failed to Bind', data);
        }
      );
    } else {
      this.goToConfirm();
    }
  }

  bindTimeOutChange($event: any) {
    console.log('bind timed out $event', $event);
  }

  downloadQuote() {
    if (!this.downloading) {
      this.downloading = true;
      this.billingInfoService.downloadQuote(this.policyStore).subscribe((resultBytes) => {
        var blob = new Blob([resultBytes], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `arch-quote-${this.policyStore.id}.pdf`;
        link.click();

        this.downloading = false;
      });
    }
  }

  private preparingQuoteTimer: ReturnType<typeof setTimeout>;
  preparingTitle: string = 'Preparing Quote';
  stopIPGPreparing(): void {
    clearTimeout(this.preparingQuoteTimer);
    this.isPreparingQuote = false;
  }
  setIPGPreparing(title: string = 'Preparing Quote'): void {
    this.preparingTitle = title;
    clearTimeout(this.preparingQuoteTimer);
    //reload page every 10 seconds until quote is no longer in progress.
    this.isPreparingQuote = true;
    this.preparingQuoteTimer = setTimeout(() => {
      this.refreshPage();
    }, 10000);
  }

  refreshPage() {
    location.reload();
  }

  preparingMessages: string[] = [
    'Your quote is being prepared.',
    'Your quote will be ready shortly. Thank you for your patience.',
  ];
  isPreparingQuote: boolean = false;
  prepareQuoteTimedOut: boolean = false;
  preparingError: string;

  prepareTimeOutChange($event: any) {
    console.log('preparing quote timed out $event', $event);
    location.reload();
    //refresh page
  }
}
